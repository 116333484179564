import React, {useState, useEffect} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {getDaigramStorage} from '../utils/localStorageDiagram';
import ViewBI from '../component/ViewBI';
import Newtabs from '../component/Newtabs';
import {Button} from '@progress/kendo-react-buttons';
import {GridLayout, GridLayoutItem} from '@progress/kendo-react-layout';
import {ControlCenter} from '../component/ControlCenter';
import {binocularsIcon} from '@progress/kendo-svg-icons';
import {useDispatch, useSelector} from 'react-redux';
import {reportsDB} from '../db/reportsDB';
import {setData, setUrl} from '../../redux/viewBISlice';
import {IsAuthorized} from '../../components/auth/IsAuthorized';
import {
	selectCredencialesArea,
	selectCredencialesRole,
	selectCredencialesUsr,
	selectCredencialesSubArea,
} from '../../redux/credencialesReducer';

export default function DashbPage() {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const report = searchParams.get('report');
	const [rute, setRute] = useState(null);
	const diagram = getDaigramStorage();
	const [isloading, setIsloading] = useState(false);
	const [tags, setTags] = useState(null);
	const [error, setError] = useState(null);
	const actualRute = useParams().actualRute;
	const [selectedButton, setSelectedButton] = useState(report);
	const [titulo, setTitulo] = useState('Reportes');
	const [open, setOpen] = useState(false);
	const area = useSelector(selectCredencialesArea);
	const rol = useSelector(selectCredencialesRole);
	const username = useSelector(selectCredencialesUsr);
	const subarea = useSelector(selectCredencialesSubArea);
	const dispatch = useDispatch();

	useEffect(() => {
		let SplitRute = actualRute.split('/');
		setTitulo(actualRute ? SplitRute[SplitRute.length - 1] : 'Reportes');
		setRute(SplitRute);
	}, [actualRute]); // Dependencia actualRute para que se actualice cuando cambie

	useEffect(() => {
		const fetchData = async () => {
			setIsloading(true);
			let datish = await setPestañas(diagram, rute);
			setTags(datish);

			setIsloading(false);
		};

		if (rute && diagram) {
			fetchData();
		}
	}, [rute]); // Dependencias rute y diagram para que se actualice cuando cambien

	const setPestañas = async (diagram, rute) => {
		if (rute !== null) {
			let nestedObject = rute?.reduce(
				(obj, key) => (obj && obj[key] ? obj[key] : null),
				diagram
			);
			//nestedObject es el arbol desde la ruta que se le pide buscar
			//puede salir nulo si la ruta llega vacia
			return Object.keys(nestedObject);
		}
		return [];
	};
	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		handleReport();
		return () => {
			dispatch(setData(null));
			dispatch(setUrl(null));
		};
	}, [report]);

	const handleReport = async () => {
		let result = 1; // 1 es el estado que representa que está cargando
		dispatch(setData(1));
		//console.log(actualRute);
		result = await reportsDB(`${actualRute}/${report}/*`, area, rol, username);

		dispatch(setData(result)); // Dispara de nuevo para cambiar el estado a 1, lo que significa que ya llegó la data
		dispatch(setUrl(`${actualRute}/${report}/*`));
	};

	let view;
	if (IsAuthorized('ControlCenter', area, rol)) {
		view = (
			<>
				<Button onClick={handleClickOpen} svgIcon={binocularsIcon}>
					{' '}
					Control Center
				</Button>{' '}
			</>
		);
	} else if (subarea === 'Data Analytics') {
		view = (
			<>
				<Button onClick={handleClickOpen} svgIcon={binocularsIcon}>
					{' '}
					Control Center
				</Button>{' '}
			</>
		);
	}
	if (isloading) {
		return <p>Cargando...</p>;
	}
	return (
		<div>
			<GridLayout
				rows={[{height: '20px'}]}
				cols={[{width: '50%'}, {width: '50%'}]}
				gap={{rows: '20px'}}>
				<GridLayoutItem row={1} col={1}>
					<h2 className="page-header-reports">{`Reportes de ${titulo}`}</h2>
				</GridLayoutItem>
				<GridLayoutItem row={1} col={2} style={{textAlign: 'end'}}>
					{view}
				</GridLayoutItem>
			</GridLayout>
			{error ? (
				<p>{error}</p>
			) : (
				<div className="tabs">
					<ul>
						<br />
						{tags ? (
							tags.map((label) => (
								<Newtabs
									key={label}
									nombreDelBoton={label}
									actualRute={`${actualRute}`}
									setSelectedButton={setSelectedButton}
									isSelected={selectedButton === label}
								/>
							))
						) : (
							<p>Etiquetas o Diagrama aún no cargados...</p>
						)}
					</ul>
				</div>
			)}
			<br />
			<ViewBI />

			<ControlCenter
				open={open}
				ReportName={selectedButton}
				onClose={handleClose}
			/>
		</div>
	);
}
