import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar/Navbar.js'
import { Topsection } from './components/Topbar/Topsection.js'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react'
import { Home } from './pages/Home.js';
import { Landing } from './pages/Landing.js';
import { PerformanceGovernance } from './pages/PerformanceGovernance.js';
import { Learning } from './pages/Learning'
import { DataGovernance } from './pages/DG'
import { DGDictionary } from './pages/DataGovernance/DGDictionary'
import { DGDefinitions } from './pages/DataGovernance/DGDefinitions'
import { DGOrgChart } from './pages/DataGovernance/DGOrgChart'
import { DGProcesses } from './pages/DataGovernance/DGProcesses'
import { ToolsDataMart } from './pages/ToolsDataMart';
import { ToolsReComm } from './pages/ToolsReComm';
import { ToolsSegmentacion } from './pages/ToolsSegmentacion';
import { ToolsStatus } from './pages/ToolsStatus';
import { Tools } from './pages/Tools';
import { ToolsBudget } from './pages/ToolsBudget';
import { Compass } from './pages/Compass';
import { CompassWare } from './pages/CompassWare';
import { CompassForecast } from './pages/CompassForecast';
import { CompassLending } from './pages/CompassLending';
import { CompassFinAdv } from './pages/CompassFinAdv';
import { Users } from './pages/Users';
import { ReportsTNBApp } from './pages/Sales/TNBApp';
import { DGSLA } from './pages/DataGovernance/DGSLAs';
import { DGDataAnalyitcsProjects } from './pages/DataGovernance/DGdataAnalyticsProjects';
import { DGDataEngineeringProjects } from './pages/DataGovernance/DGdataEngineeringProjects';
import { DGDataScienceProjects } from './pages/DataGovernance/DGdataScienceProjects';
import { DGDataProjects } from './pages/DataGovernance/DGdataProjects';
import { DGDataModel } from './pages/DataGovernance/DGdataModel';
import { DGQueryList } from './pages/DataGovernance/DGQueryList';
import { Siignal } from './pages/Customer/Siignal'
import { HelpDeskReportingLeader } from './pages/DataAnalytics/ReportsHelpDeskLeader';
import { InvoiceCertification } from './pages/Customer/InvoiceCertification'
import { TranferencesHelper } from './pages/Customer/TranferencesHelper'
import { TranferencesHelperMX } from './pages/Customer/TranferencesHelperMX.js';
import { TranferencesHelperECU } from './pages/Customer/TranferencesHelperECU.js';
import { TranferencesHelperURU } from './pages/Customer/TranferencesHelperURU.js';
import { ProcessTreeRenewals } from './pages/Customer/ProcessTreeRenewals'
import { CustomerReportsValoracionesTopPerformance } from './pages/Customer/reporting_Inbound_Valoraciones';
import { CustomerReportsComunicaciones } from './pages/Customer/Comunicaciones';
import { ShiftNovelties } from './components/Dashboards/Customer/ShiftNovelties/ShiftNovelties'
import { LearningDevelopmentManagement } from './pages/Training/appLD';
import ReportsPage from './reports/pages/ReportsPage';
import DashbPage from './reports/pages/DashbPage';
import AdminPage from './adminPage/adminPage';
import AdminBotones from './adminPage/AdminBotones';
import AccessModule from './adminPage/AccessConfigurationModule';
import ConnectDB from './reports/db/conectDB';
import { SchedulerV2 } from './components/timeReportingV2/Schedulerv2.js';
import { HelpRoutes } from './HelpDesk/router/HelpRoutes.js';
import MonthOperation from './components/dayOperation/MonthOperation.js';
import ChatBox from './Chatbot/components/ChatBox.jsx';
import { IsAuthorized } from './components/auth/IsAuthorized.js';
import  Chatmicrosoft from './pages/Chatmicrosoft.jsx';
import { LobbyReports } from './reports/pages/LobbyReports.jsx';
import { UseRoles } from './components/usersManagement/roles.js';

import { UniqueDesk } from './pages/Customer/UniqueDesk.js';
import { ExampleUse } from './components/GridTemplate/ExampleUse.js';
import GuestHome from './pages/viewsHome/Guest/GuestHome.js';
import { PricingJobs } from './pages/DatabricksRep/PricingJobs.js';
import { Holcim } from './pages/Holcim.js';




function App() {
  const isAuthenticated = useIsAuthenticated();
  //UseAxiosAuth();
  const { roleData, loadingRoles } = UseRoles(isAuthenticated); 
  const isAllowedChatbotAlice=IsAuthorized('ChatbotAlice',roleData?.area,roleData?.role);
  const [inactive, setInactive] = useState(false);

  if(loadingRoles){
    <Landing charging={true}/>
  }
  if(roleData&&roleData.role==='Not Found'){
    return <Landing ErrorMessage={'No se encontró el usuario en la base de datos'}/>
  }



  const authenticatedRoutes = (
    <Routes>
              <Route path={'/'} element={<Home />} />
              <Route path='Users' element={<Users />} />         
              <Route path="/Reports/:actualRute" element={<ReportsPage/>} />
              <Route path='/Reports/' element={<ReportsPage/>}/>
              <Route path='/LobbyReports/:actualRute' element={<LobbyReports/>}/>
              <Route path='/LobbyReports/' element={<LobbyReports/>}/>
              <Route path='/dashboardpage/:actualRute' element={<DashbPage/>}/>
              <Route path='/dashboardpage/*' element={<DashbPage/>}/>  
              <Route path="/adminPage/*" element={<AdminPage/>}>
                <Route path="AccessModule" element={<AccessModule/>} />
                <Route path="botones" element={<AdminBotones/>} />
              </Route>
              <Route path='/Reports/Databricks/Pricing' element={<PricingJobs/>} /> 
              <Route path='/Reports/ReportsData/DayOperation' element={<MonthOperation/>} />
              <Route path='/Reports/ReportsData/HelpDeskLeadership' element={<HelpDeskReportingLeader />} />
              <Route path='/Reports/ReportsData/UserHome' element={<SchedulerV2/>} />
              <Route path='/Reports/ReportsCustomer/Siignal' element={<Siignal />} />
              <Route path='/Reports/ReportsCustomer/TranferencesHelper' element={<TranferencesHelper />} />
              <Route path='/Reports/ReportsCustomer/ProcessTreeRenewals' element={<ProcessTreeRenewals />} />
              <Route path='/Reports/ReportsCustomer/TranferencesHelperMX' element={<TranferencesHelperMX />} />
              <Route path='/Reports/ReportsCustomer/TranferencesHelperECU' element={<TranferencesHelperECU />} />
              <Route path='/Reports/ReportsCustomer/TranferencesHelperURU' element={<TranferencesHelperURU />} />
              <Route path='Training' element={<Learning />} />
              <Route path='/chatbot' element={<ChatBox />} />

              <Route path='/Training/Tools/TNBApp' element={<ReportsTNBApp />} />
              <Route path='/Training/Reports/Valoraciones' element={<CustomerReportsValoracionesTopPerformance />} />
              <Route path='/Training/Reports/Comunicaciones' element={<CustomerReportsComunicaciones />} />
              <Route path='/Learning/Tools/Management' element={<LearningDevelopmentManagement />} />
              <Route path='DataGovernance' element={<DataGovernance />} />
              <Route path='/DataGovernance/Definitions' element={<DGDefinitions />} />
              <Route path='/DataGovernance/Dictionary' element={<DGDictionary />} />
              <Route path='/DataGovernance/DataModel' element={<DGDataModel />} />
              <Route path='/DataGovernance/QueryList' element={<DGQueryList />} />
              <Route path='/DataGovernance/Projects' element={<DGDataProjects />} />
              <Route path='/DataGovernance/Projects/DataAnalytics' element={<DGDataAnalyitcsProjects />} />
              <Route path='/DataGovernance/Projects/DataEngineering' element={<DGDataEngineeringProjects />} />
              <Route path='/DataGovernance/Projects/DataScience' element={<DGDataScienceProjects />} />
              <Route path='/DataGovernance/SLAs' element={<DGSLA />} />
              <Route path='/DataGovernance/OrgChart' element={<DGOrgChart />} />
              <Route path='/DataGovernance/Processes' element={<DGProcesses />} />
              <Route path='/HelpDesk/*' element={<HelpRoutes/>} />
              <Route path='/Template/Grid/:type' element={<ExampleUse/>} />
              <Route path='Tools' element={<Tools />} />
              <Route path='/Tools/DataMart' element={<ToolsDataMart />} />
              <Route path='/Tools/UniqueDesk' element={<UniqueDesk />} />
              <Route path='/Tools/Wayfinder' element={<ToolsReComm />} />
              <Route path='/Tools/Segmentacion' element={<ToolsSegmentacion />} />
              <Route path='/Tools/ProductStatus' element={<ToolsStatus />} />
              <Route path='/Tools/Budget' element={<ToolsBudget />} />
              <Route path='/Tools/InvoicesCertification' element={<InvoiceCertification />} />
              <Route path='/Tools/ShiftNovelties' element={<ShiftNovelties />} />
              <Route path='Compass' element={<Compass />} />
              <Route path='/Compass/Ware' element={<CompassWare />} />
              <Route path='/Compass/Forecast' element={<CompassForecast />} />
              <Route path='/Compass/Lending' element={<CompassLending />} />
              <Route path='/Compass/FinAdvisor' element={<CompassFinAdv />} />
              <Route path='PerformanceGovernance' element={<PerformanceGovernance />} />
              <Route path='/Holcim/Reports' element={<Holcim/>} />

            </Routes>
  );
  const guestRoutes = (
    <Routes>
      <Route path="/" element={<GuestHome />} /> 
      <Route path='/Template/Grid/:type' element={<ExampleUse/>} />
      <Route path='/Holcim/Reports' element={<Holcim/>} />
      <Route path="*" element={<GuestHome />} /> 
    </Routes>
  );
  return (
    <div className="App">
      <ConnectDB/>
      <AuthenticatedTemplate>
        <Router>
          <Navbar onCollapse={(inactive) => setInactive(inactive)} />
          <Topsection />
          <div className='bottom-section'>
          {roleData && roleData.role === 'Guest' ?<></>:<Chatmicrosoft isAllowedChatbotAlice={isAllowedChatbotAlice}/>}
          
          </div>
          <div className={`container-SDR ${inactive ? 'inactive' : ''}`}>

            {roleData && roleData.role === 'Guest' ?  guestRoutes:authenticatedRoutes}
          </div>
        </Router>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Landing />
      </UnauthenticatedTemplate>
    </div>
  );
}

export default App;