import { MyCommandCell } from "./topicsCommandCell";
import { Grid, GridColumn as Column, GridColumnMenuFilter, GridColumnMenuSort, GridToolbar } from "@progress/kendo-react-grid";
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import {ActiveCell} from './GridCells';
import { process } from '@progress/kendo-data-query';
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Button } from '@progress/kendo-react-buttons'
import { StatusEditor } from "./topicsSampleProducts";
import { useCallback, useEffect, useRef, useState } from "react";
import { fileExcelIcon } from "@progress/kendo-svg-icons";
import { baseURL } from "../../Config";
const editField = "inEdit";

export const ColumnMenu = (props) => {
  return (
      <div>
          <GridColumnMenuSort {...props} />
          <GridColumnMenuFilter {...props} />
      </div>
  );
}





export const TopicsTable = () => {
  const [dataDetails, setDataDetails] = useState([]);
  const [isloading,setLoading] = useState(true);
  const [take, setTake] = useState();
  const [skip, setSkip] = useState(0);
  const [sort, setSort] = useState([]);
  const [group, setGroup] = useState([]);
  const [filter, setFilter] = useState(null);
  const _export = useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };


  useEffect(() => {
    const loadGet = async () => {
      const response = await axios.get(`${baseURL}/api/v1/TNB/TrainingTopics/Details`);
      const test=response.data
      setDataDetails(test)
      setLoading(false);
    }
    loadGet();
},[]);

const dataState = {
  take,
  skip,
  sort,
  group,
  filter
};

const onDataStateChange = useCallback(
  (event) => {
      setTake(event.dataState.take);
      setSkip(event.dataState.skip);
      setSort(event.dataState.sort);
      setGroup(event.dataState.group);
      setFilter(event.dataState.filter);
  },
  [setTake, setSkip, setSort, setGroup]
);
let data=[...dataDetails]

const insertItem = item => {
  item.id_actividad = 200;
  item.inEdit = false;
  console.log(item)
  data.unshift(item);
  return data;
};
const getItems = () => {
  return data;
};
const updateItem = item => {
  let index = data.findIndex(record => record.id_actividad === item.id_actividad);
  data[index] = item;
  return data;
};
const deleteItem = item => {
  let index = data.findIndex(record => record.id_actividad === item.id_actividad);
  data.splice(index, 1);
  return data;
};



  // modify the data in the store, db etc
  const remove = dataItem => {
    axios.post(`${baseURL}/api/v1/TNB/TrainingTopics/DeleteOne`, dataItem).then((response)=>{
      setDataDetails(response.data)})
  };
  const add = dataItem => {
    dataItem.inEdit = false;
    axios.post(`${baseURL}/api/v1/TNB/TrainingTopics/InsertNew`, dataItem).then((response)=>{
      setDataDetails(response.data)
  })
    ;
  };
  const update = dataItem => {
    dataItem.inEdit = false;
    axios.post(`${baseURL}/api/v1/TNB/TrainingTopics/UpdateOne`, dataItem).then((response)=>{
      setDataDetails(response.data)})
  };

  // Local state operations
  const discard = () => {
    const newData = [...dataDetails];
    newData.splice(0, 1);
    setDataDetails(newData);
  };
  const cancel = dataItem => {
    setDataDetails(dataDetails.map(item => item.id_actividad === dataItem.id_actividad ? {
        ...item,
        inEdit: false
      } : item));
  };
  const enterEdit = dataItem => {
    setDataDetails(dataDetails.map(item => item.id_actividad === dataItem.id_actividad ? {
      ...item,
      inEdit: true
    } : item));
  };
  const itemChange = event => {
    const newData = dataDetails.map(item => item.id_actividad === event.dataItem.id_actividad ? {
      ...item,
      [event.field || '']: event.value
    } : item);
    setDataDetails(newData);
  };
  const addNew = () => {
    const newDataItem = {
      inEdit: true,
    };
    setDataDetails([newDataItem, ...dataDetails]);
  };
  const scrollHandler = (event) => {
    const target = event.target;
    
    if (target.scrollTop + target.clientHeight >= target.scrollHeight - 10) {
      const moreData = dataDetails.slice(skip, skip + 10); 
      
      if (moreData.length > 0) {
        setDataDetails((oldData) => [...oldData, ...moreData]); 
        setSkip(skip + 10);
      }
    }
  };

  const CommandCell = props => <MyCommandCell {...props} edit={enterEdit} remove={remove} add={add} discard={discard} update={update} cancel={cancel} editField={editField} />;

  const processedData = process(dataDetails, dataState);

  if (isloading) {
    return <div className='desktop-view-SDR'>
                <div id='reportContainerSDR'>
                <div className='centerDS'><CircularProgress/></div>
                </div>
            </div>
} else 
  return (    
  <>
  <ExcelExport data={dataDetails} ref={_export}>
  <Grid
      style={{ height: "716px", overflow: "auto" }}
      onScroll={scrollHandler} 
      data={processedData}
      onItemChange={itemChange}
      onDataStateChange={onDataStateChange}
      editField={editField}
    >
    <GridToolbar>
            <Button title="Add new" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary" onClick={addNew}>
              Agregar
            </Button>
            <Button
                    svgIcon={fileExcelIcon}
                    onClick={excelExport}
                ></Button>
      </GridToolbar>
      <Column field={"id_actividad"} title={"Id"} width="60px" editable={false} columnMenu={ColumnMenu} />
      <Column field={"nombreActividad"} title={"Tema"} columnMenu={ColumnMenu} />
      <Column field={"duracionHoras"} title={"Duración"} editor="numeric" />
      <Column field={"notaAprobatoria"} title={"Nota"} editor="numeric" />
      <Column field={"estado"} title={"Estado"} editor="boolean" cell={StatusEditor} />
      <Column cell={CommandCell} title={"Acciones"} />
    </Grid>
    </ExcelExport></>
  )
  
};
