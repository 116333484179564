import {
	FaBriefcase,
	FaCircleNotch,
} from 'react-icons/fa';
import {MiddleList} from '../../../components/ViewCustomer/components/MidleList';
const ACVplus = () => {
	let dataComercial = sessionStorage.getItem('dataComercial');
	dataComercial = JSON.parse(dataComercial);
	let ACVLogrado;
	let ACVLogradoNumber;
	if (dataComercial) {
		ACVLogrado = dataComercial.ACVLogrado?.ACV_Logrado;
		if (typeof ACVLogrado === 'number') {
			ACVLogradoNumber = Number(ACVLogrado);
			ACVLogrado = ACVLogrado.toFixed(2);
			ACVLogrado = Number(ACVLogrado).toLocaleString('en-US');
		} else {
			ACVLogrado = 0;
			ACVLogradoNumber = 0;
		}
	} else {
		ACVLogrado = 0;
	}
	//.dashboard-card-content-number-price_small
	return (
		<div className="dashboard-card-content">
			<p
				className={
					ACVLogradoNumber > 99999
						? 'dashboard-card-content-number-price_small'
						: 'dashboard-card-content-number-price'
				}>
				$ {ACVLogrado}
			</p>
			<div>
				<p className="footer"></p>
			</div>
		</div>
	);
};
const TotalFacturado = () => {
	let dataComercial = sessionStorage.getItem('dataComercial');
	dataComercial = JSON.parse(dataComercial);
	let TotalFacturado;
	let TotalFacturadoNumber;
	if (dataComercial) {
		TotalFacturado = dataComercial.FacturadoMeta?.Total_Facturado;
		if (typeof TotalFacturado === 'number') {
			TotalFacturadoNumber = Number(TotalFacturado);
			TotalFacturado = TotalFacturado.toFixed(2);
			TotalFacturado = Number(TotalFacturado).toLocaleString('en-US');
		} else {
			TotalFacturado = 0;
			TotalFacturadoNumber = 0;
		}
	} else {
		TotalFacturado = 0;
	}
	return (
		<div className="dashboard-card-content">
			<p
				className={
					TotalFacturadoNumber > 99999
						? 'dashboard-card-content-number-price_small'
						: 'dashboard-card-content-number-price'
				}>
				$ {TotalFacturado}
			</p>
			<div>
				<p className="footer"></p>
			</div>
		</div>
	);
};
const Efectividad = () => {
	let dataComercial = sessionStorage.getItem('dataComercial');
	dataComercial = JSON.parse(dataComercial);
	let totalCotizacionesAbiertas;
	if (dataComercial) {
		totalCotizacionesAbiertas =
			dataComercial.totalCotizacionesAbiertas?.totalCotizacionesAbiertas;
	} else {
		totalCotizacionesAbiertas = 0;
	}
	return (
		<div className="dashboard-card-content">
			<p
				className={`dashboard-card-content-number-price ${
					totalCotizacionesAbiertas === 0 ? 'gray' : ''
				}`}>
				{totalCotizacionesAbiertas}
			</p>
			<div>
				<p className="footer"></p>
			</div>
		</div>
	);
};
const NotasCrédito = () => {
	let dataComercial = sessionStorage.getItem('dataComercial');
	dataComercial = JSON.parse(dataComercial);
	let NotasCrédito = {};
	if (dataComercial) {
		NotasCrédito = {
			'Total Notas Crédito':
				dataComercial.totalNotasCredito?.totalNotasCredito || 0,
			'Ptes por Refacturar':
				dataComercial.totalPdteFacturar?.totalPdteFacturar || 0,
		};
	} else {
		NotasCrédito = {
			'Total Notas Crédito': 0,
			'Ptes por Refacturar': 0,
		};
	}
	return (
		<div className="dashboard-card-content">
			<ul className="ul-customer">
				{Object.entries(NotasCrédito).map(([key, value], index) => {
					return (
						<li key={index} className="li-customer">
							<span>{key}: </span>
							{value}
						</li>
					);
				})}
			</ul>
		</div>
	);
};
const FlujodeTrabajo = () => {
	let dataComercial = sessionStorage.getItem('dataComercial');
	dataComercial = JSON.parse(dataComercial);
	let seguimientos;
	if (dataComercial && dataComercial.hasOwnProperty('seguimientos')) {
		({seguimientos} = dataComercial);
	} else {
		console.log('noooooo');
		seguimientos = {
			Avance_0: 0,
			Avance_5: 0,
			Avance_30: 0,
			Avance_80: 0,
			Avance_100: 0,
			'P-Avance_0': 0,
			'P-Avance_5': 0,
			'P-Avance_30': 0,
			'P-Avance_80': 0,
			'P-Avance_100': 0,
			Total_Abiertos: 0,
		};
	}
	const celdas = [
		{
			name: 'Avances 0%',
			valor: 'Avance_0',
			porcentaje: 'P_Avance_0',
		},
		{
			name: 'Avances 5%',
			valor: 'Avance_5',
			porcentaje: 'P_Avance_5',
		},
		{
			name: 'Avances 30%',
			valor: 'Avance_30',
			porcentaje: 'P_Avance_30',
		},
		{
			name: 'Avances 80%',
			valor: 'Avance_80',
			porcentaje: 'P_Avance_80',
		},
		{
			name: 'Avances 100%',
			valor: 'Avance_100',
			porcentaje: 'P_Avance_100',
		},
	];

	return (
		<div className="dashboard-card-content">
			<div className="card-content">
				<table className="CrossSellTable">
					<thead>
						<tr>
							<th>Avance</th>
							<th>#</th>
							<th>%</th>
						</tr>
					</thead>
					<tbody>
						{celdas.map((key, index) => {
							return (
								<tr key={index}>
									<td>{key.name}</td>
									<td>{seguimientos[key.valor]}</td>
									<td>{seguimientos[key.porcentaje]}</td>
								</tr>
							);
						})}
					</tbody>
					<tfoot>
						<tr>
							<th>Total Abiertos</th>
							<th>{seguimientos.Total_Abiertos}</th>
							<th>100%</th>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	);
};
const AtrasadosCard = () => {
	let dataComercial = sessionStorage.getItem('dataComercial');
	dataComercial = JSON.parse(dataComercial);
	let seguimientos;
	const celdas = [
		{
			name: 'Porcentaje Atraso',
			field: 'P_Atrasos',
		}
	];
	if (dataComercial && dataComercial.hasOwnProperty('seguimientos')) {
		({seguimientos} = dataComercial);
		
	} else {
		seguimientos = {
			Total_Atrasos: "0.0%",
			P_Atrasos: 0,
		};
	}

	const iconSelector = (key) => {
		switch (key) {
			case 'Porcentaje Atraso':
				return <FaCircleNotch />;
			case 'Comerciales':
				return <FaBriefcase />;
			default:
				return null;
		}
	};

	return (
		<div className="dashboard-card-content">
			<div className="card-content">
				<ul>
					{celdas.map((field, index) => {
							return (
								<li key={index} className="li-customer">
									<span className="icon-home">{iconSelector(field.name)}</span> {field.name}:{' '}
									{seguimientos[field.field]}
								</li>
							);
						})}
				</ul>
				<div>
					<p className="footer-home">
						Seguimientos Atrasados <span>{seguimientos.Total_Atrasos}</span>
					</p>
				</div>
			</div>
		</div>
	);
};

export const CrossSellTiles = [
	{
		defaultPosition: {
			col: 1,
			colSpan: 2,
			rowSpan: 1,
		},
		header: 'ACV + Total',
		body: <ACVplus />,
	},
	{
		defaultPosition: {
			col: 3,
			colSpan: 2,
			rowSpan: 1,
		},
		header: 'Total Facturado',
		body: <TotalFacturado />,
	},
	{
		defaultPosition: {
			col: 5,
			colSpan: 2,
			rowSpan: 1,
		},
		header: 'Cotizaciones Abiertas',
		body: <Efectividad />,
		resizable: 'vertical',
	},
	{
		defaultPosition: {
			col: 7,
			colSpan: 2,
			rowSpan: 1,
		},
		header: 'Notas Crédito',
		body: <NotasCrédito />,
		resizable: 'vertical',
	},
	{
		defaultPosition: {
			col: 7,
			colSpan: 2,
			rowSpan: 4,
		},
		body: (
			<div className="dashboard-card-content">
				<MiddleList
					title={'Avances 0% y al 5%'}
					valueInit={'tareasRealizarMenor5'}
				/>
			</div>
		),
		resizable: 'vertical',
		reorderable: false,
	},
	{
		defaultPosition: {
			col: 5,
			colSpan: 2,
			rowSpan: 4,
		},
		body: (
			<div className="dashboard-card-content">
				<MiddleList
					title={'Seguimientos al 30%'}
					valueInit={'tareasRealizarMenor30'}
				/>
			</div>
		),
		resizable: 'vertical',
		reorderable: false,
	},
	{
		defaultPosition: {
			col: 3,
			colSpan: 2,
			rowSpan: 4,
		},
		body: (
			<div className="dashboard-card-content">
				<MiddleList
					title={'Seguimientos al 80%'}
					valueInit={'tareasRealizarMayor80'}
				/>
			</div>
		),
		resizable: 'vertical',
		reorderable: false,
	},
	{
		defaultPosition: {
			col: 1,
			colSpan: 2,
			rowSpan: 2,
		},
		header: 'Flujo de Trabajo',
		body: <FlujodeTrabajo />,
		resizable: 'horizontal',
	},
	{
		defaultPosition: {
			col: 1,
			colSpan: 2,
			rowSpan: 2,
		},
		header: '_',
		body: <AtrasadosCard />,
	},
];
