import React, { useState } from 'react';
import { TextField, Select, MenuItem, Button, FormControl, InputLabel, Box } from '@mui/material';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setSubMenu } from '../../../../../../../redux/ControlCenterReducer';

const ReusableForm = ({defaultValue, fieldConfig, endpoint, UrlReport,email,setToast}) => {

    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        let data={
            route: UrlReport,
            filter: 'fields',
            fields: formData,
            user: email,
        }
        try {
            const response = await axios.put(endpoint, data);
            console.log('Data sent successfully:', response.data);
            setToast({
                snackbarOpen: true,
                type: 'success',
                message: 'Data sent successfully',
            });
        } catch (error) {
            console.error('Error sending data:', error);
            setToast({
                snackbarOpen: true,
                type: 'error',
                message: 'Error sending data',
            });
        }
        dispatch(setSubMenu('Updates'));
    };

    const renderField = (field) => {
        const { type, name, label, options } = field;
        switch (type) {
            case 'text':
                return (
                    <TextField
                        key={name}
                        name={name}
                        defaultValue={defaultValue || ''}
                        label={label}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                );
            case 'select':
                return (
                    <FormControl fullWidth margin="normal" key={name}>
                        <InputLabel>{label}</InputLabel>
                        <Select name={name} value={formData[name] || defaultValue} onChange={handleChange} defaultValue={defaultValue}>
                            {options.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            case 'textarea':
                return (
                    <TextField
                        key={name}
                        name={name}
                        defaultValue={defaultValue || ''}
                        label={label}
                        multiline
                        rows={4}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Box component="form" noValidate autoComplete="off" sx={{ width: 740 }}>
            {fieldConfig.map((field) => renderField(field))}
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{ mt: 2 }}
            >
                Enviar
            </Button>
        </Box>
    );
};

export default ReusableForm;
