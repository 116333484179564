import * as React from 'react';
import {AutoComplete, DropDownList} from '@progress/kendo-react-dropdowns';
import axios from 'axios';
import {filterBy} from '@progress/kendo-data-query';
import {myContext} from './trainingParticipantsTable';
import {topicsContext} from './trainingTopicsTable';
import {TextArea, Checkbox} from '@progress/kendo-react-inputs';
import {Badge} from '@progress/kendo-react-indicators';
import { useSelector } from 'react-redux';
import { selectAllStatusCapacitacion, selectDetalleCapacitacion } from '../../redux/TNBSlice';

export const ParticipantsDrowpdown = (props) => {
	const [activeEmployee, setActiveEmployee] = React.useState([]);//lista de empleados
	const [isloadingEmployee, setLoadingEmployee] = React.useState(true);//booleano  para determinar si los datos de los empleados aún se están cargando.
	const [activeEmployeeFinal, setActiveEmployeeFinal] = React.useState();//utilizado para almacenar el valor seleccionado de un dropdown.
	const [filterEmployeeData, setFilterEmployeeData] = React.useState(
		activeEmployee.slice()
	);//Almacena los empleados filtrados
	React.useEffect(() => {
		const loadGet = async () => {
			const responseEmployee = await axios.get('/api/Employees');
			const testEmployee = responseEmployee.data;
			setActiveEmployee(testEmployee);
						setLoadingEmployee(false);
		};
		loadGet();
	}, []);
	
	const filterActiveEmployeesData = (filter) => {
		const filterEmployeeData = activeEmployee.slice();
		return filterBy(filterEmployeeData, filter);
	};

	const filterEmployeeDataChange = (event) => {
		setFilterEmployeeData(filterActiveEmployeesData(event.filter));
	};
	
	const handleChange = (event) => {
		setActiveEmployeeFinal(event.target.value.documentoParticipante);
	};
	const {dataItem} = props;
	const field = props.field || '';
	const dataValue = dataItem[field] === undefined ? '' : dataItem[field];
	if (isloadingEmployee) {
		return <div>Loading...</div>;
	} else {
		return (
			<td>
				{dataItem.inEdit ? (
					<DropDownList
						data={filterEmployeeData}
						value={activeEmployeeFinal}
						filterable={true}
						onFilterChange={filterEmployeeDataChange}
						onChange={handleChange}
						textField={'documentoParticipante'}
						id="documentoParticipante"
						style={{
							width: '100%',
						}}
					/>
				) : (
					dataValue.toString()
				)}
			</td>
		);
	}
};
//`${baseURL}/api/v1/TNB/Parametros?id=${'subcapacitacion'}`
export const TopicsParticipantsDrowpdown = (props) => {
	const [activeTopicEmployee, setActiveTopicEmployee] = React.useState([]);
	const [isloadingTopicEmployee, setLoadingTopicEmployee] =
		React.useState(true);
		const capacitacion = useSelector(selectDetalleCapacitacion) || {};
		const temas = capacitacion.Temas;

	React.useEffect(() => {
        setActiveTopicEmployee(temas);
        setLoadingTopicEmployee(false);
    }, [temas]);
	
	const handleChange = (e) => {
		if (props.onChange) {
			props.onChange({
				dataIndex: 0,
				dataItem: props.dataItem,
				field: props.field,
				syntheticEvent: e.syntheticEvent,
				value: e.target.value.nombreActividad,
			});
		}
	};
	const {dataItem} = props;
	const field = props.field || '';
	const dataValue =
		dataItem[field] === null || dataItem[field] === undefined
			? ''
			: dataItem[field];
	//   if (isloadingEmployee) {return <div>Loading...</div>}
	//   else
	return (
		<td>
			{dataItem.inEdit ? (
				<DropDownList
					style={{
						width: '100%',
					}}
					onChange={handleChange}
					value={activeTopicEmployee && activeTopicEmployee.find(
						(c) => c.nombreActividad === dataValue
					)}
					data={activeTopicEmployee}
					textField="nombreActividad"
				/>
			) : (
				dataValue.toString()
			)}
		</td>
	);
};

export const TopicsParticipantsStatusDrowpdown = (props) =>{
	const [activeTopicEmployeeStatus, setActiveTopicEmployeeStatus] =React.useState([]);
	const [isloadingTopicEmployeeStatus, setLoadingTopicEmployeeStatus] =React.useState(true);
	const statusCapacitacion=useSelector(selectAllStatusCapacitacion);
	
	React.useEffect(()=>{
		const getListStatus=()=>{
			//detalle de la capacitación completa
			if(statusCapacitacion){
				setActiveTopicEmployeeStatus(statusCapacitacion)
			}			
		}
		getListStatus()
	},[statusCapacitacion])

	const handleChange = (e) => {
		if (props.onChange) {
			props.onChange({
				dataIndex: 0,
				dataItem: props.dataItem,
				field: props.field,
				syntheticEvent: e.syntheticEvent,
				value: e.target.value.estado,
			});
		}
	};
	const {dataItem} = props;
	const field = props.field || '';
	const dataValue =
		dataItem[field] === null || dataItem[field] === undefined
			? ''
			: dataItem[field];
	//   if (isloadingEmployee) {return <div>Loading...</div>}
	//   else
	return (
		<td>
			{dataItem.inEdit ? (
				<DropDownList
					style={{
						width: '100%',
					}}
					onChange={handleChange}
					value={ activeTopicEmployeeStatus.find((c) => c.estado === dataValue)}
					data={activeTopicEmployeeStatus}
					textField="estado"
				/>
			) : (
				dataValue.toString()
			)}
		</td>
	);
}; 

export const FeedbackField = (props) => {
	const {dataItem} = props;
	const field = props.field || '';
	const dataValue = dataItem[field] === null ? '' : dataItem[field];
	return (
		<td>
			{dataItem.inEdit ? (
				<TextArea
					id={'observaciones'}
					name={'observaciones'}
					maxlength={500}
					rows={4}
					value={dataValue}
				/>
			) : (
				dataValue.toString()
			)}
		</td>
	);
};

export const DesertorStatus = (props) => {
	let view;
	const onChange = (e) => {
		if (props.onChange) {
			props.onChange({
				dataIndex: 0,
				dataItem: props.dataItem,
				field: props.field,
				syntheticEvent: e.syntheticEvent,
				value: e.target.value,
			});
		}
	};
	const {dataItem} = props;
	const field = props.field || '';
	const dataValue =
		dataItem[field] === undefined || dataItem[field] === null
			? ''
			: dataItem[field];
	const badgeStyle = {
		position: 'relative',
		display: 'inline',
		fontSize: '11px',
	};

	{
		dataValue === true
			? (view = (
					<Badge
						themeColor={'error'}
						shape={'rounded'}
						style={badgeStyle}
						fill={'outline'}>
						Si
					</Badge>
			  ))
			: (view = (
					<Badge
						themeColor={'success'}
						shape={'rounded'}
						style={badgeStyle}
						fill={'outline'}>
						No
					</Badge>
			  ));
	}
	return (
		<td style={{textAlign: 'center'}}>
			{dataItem.inEdit ? (
				<Checkbox
					defaultValue={dataValue}
					onChange={onChange}
					textField="desertor"
				/>
			) : (
				<>{view}</>
			)}
		</td>
	);
};

export const ParticipoStatus = (props) => {
	let view;
	const onChange = (e) => {
		if (props.onChange) {
			props.onChange({
				dataIndex: 0,
				dataItem: props.dataItem,
				field: props.field,
				syntheticEvent: e.syntheticEvent,
				value: e.target.value,
			});
		}
	};
	const {dataItem} = props;
	const field = props.field || '';
	const dataValue =
		dataItem[field] === undefined || dataItem[field] === null
			? ''
			: dataItem[field];
	const badgeStyle = {
		position: 'relative',
		display: 'inline',
		fontSize: '11px',
	};

	{
		dataValue === true
			? (view = (
					<Badge
						themeColor={'success'}
						shape={'rounded'}
						style={badgeStyle}
						fill={'outline'}>
						Si
					</Badge>
			  ))
			: (view = (
					<Badge
						themeColor={'error'}
						shape={'rounded'}
						style={badgeStyle}
						fill={'outline'}>
						No
					</Badge>
			  ));
	}
	return (
		<td style={{textAlign: 'center'}}>
			{dataItem.inEdit ? (
				<Checkbox
					defaultValue={dataValue}
					onChange={onChange}
					textField="participo"
				/>
			) : (
				<>{view}</>
			)}
		</td>
	);
};

export const ExternoStatus = (props) => {
	let view;
	const onChange = (e) => {
		if (props.onChange) {
			props.onChange({
				dataIndex: 0,
				dataItem: props.dataItem,
				field: props.field,
				syntheticEvent: e.syntheticEvent,
				value: e.target.value,
			});
		}
	};
	const {dataItem} = props;
	const field = props.field || '';
	const dataValue =
		dataItem[field] === undefined || dataItem[field] === null
			? ''
			: dataItem[field];
	const badgeStyle = {
		position: 'relative',
		display: 'inline',
		fontSize: '11px',
	};

	{
		dataValue === true
			? (view = (
					<Badge
						themeColor={'success'}
						shape={'rounded'}
						style={badgeStyle}
						fill={'outline'}>
						Si
					</Badge>
			  ))
			: (view = (
					<Badge
						themeColor={'light'}
						shape={'rounded'}
						style={badgeStyle}
						fill={'outline'}>
						No
					</Badge>
			  ));
	}
	return (
		<td style={{textAlign: 'center'}}>
			{dataItem.inEdit ? (
				<Checkbox
					defaultValue={dataValue}
					onChange={onChange}
					textField="externo"
				/>
			) : (
				<>{view}</>
			)}
		</td>
	);
};


// export const TopicsParticipantsDrowpdown = (props) => {
// 	const [activeTopicEmployee, setActiveTopicEmployee] = React.useState([]);
// 	const [isloadingTopicEmployee, setLoadingTopicEmployee] =
// 		React.useState(true);
// 	const trainingName = React.useContext(myContext);
// 	React.useEffect(() => {
// 		const loadGet = async () => {
// 			const responseTopicEmployee = await axios.get(
// 				`/api/TNB/TrainingDetails/Participants/Topics?id=${trainingName}`
// 			);
// 			const testTopicEmployee = responseTopicEmployee.data;
// 			setActiveTopicEmployee(testTopicEmployee);
// 			setLoadingTopicEmployee(false);
// 		};
// 		loadGet();
// 	}, []);

// 	const handleChange = (e) => {
// 		if (props.onChange) {
// 			props.onChange({
// 				dataIndex: 0,
// 				dataItem: props.dataItem,
// 				field: props.field,
// 				syntheticEvent: e.syntheticEvent,
// 				value: e.target.value.nombreActividad,
// 			});
// 		}
// 	};
// 	const {dataItem} = props;
// 	const field = props.field || '';
// 	const dataValue =
// 		dataItem[field] === null || dataItem[field] === undefined
// 			? ''
// 			: dataItem[field];
// 	//   if (isloadingEmployee) {return <div>Loading...</div>}
// 	//   else
// 	return (
// 		<td>
// 			{dataItem.inEdit ? (
// 				<DropDownList
// 					style={{
// 						width: '100%',
// 					}}
// 					onChange={handleChange}
// 					value={activeTopicEmployee.find(
// 						(c) => c.nombreActividad === dataValue
// 					)}
// 					data={activeTopicEmployee}
// 					textField="nombreActividad"
// 				/>
// 			) : (
// 				dataValue.toString()
// 			)}
// 		</td>
// 	);
// };