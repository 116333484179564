import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {selectData, selectUrl, setData, setUrl} from '../../redux/viewBISlice';
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {models} from 'powerbi-client';
import {PowerBIEmbed} from 'powerbi-client-react';
import '../../App.css';
import {CircularProgress} from '@mui/material';
import { LogsDB } from '../db/LogsDB';
import { selectCredencialesArea, selectCredencialesRole, selectCredencialesUsr } from '../../redux/credencialesReducer';



function ViewBI() {
	let rutetoSend = useSelector(selectUrl);

	let data = useSelector(selectData);
	const area = useSelector(selectCredencialesArea);
    const rol=useSelector(selectCredencialesRole);
	const username=useSelector(selectCredencialesUsr);

	if(!data){data=1}
	let mensaje= null
	let results = '';
	let accessToken = '';
	let embedUrl = '';
	let id = '';
	if(data!==1){
		results = JSON.stringify(data);
		results = JSON.parse(results);
		if (typeof results.accessToken === 'undefined') {
				mensaje=results
				results = '';
		}
		else {
			if(typeof results.reportConfig=== 'undefined'){
				accessToken= results.accessToken
				embedUrl=results.embedUrl
				id=results.id
			} 
			else {
				accessToken= results.accessToken
				embedUrl=results.reportConfig.embedUrl
				id=results.reportConfig.reportId
			} 
			}
	}
	useEffect(() => {
            const temporizador = setTimeout(() => {
                let resultado = LogsDB(rutetoSend,area,rol,username);
            }, 15000);
            return () => clearTimeout(temporizador);
    }, [rutetoSend]);

	const dispatch = useDispatch();
	

	useEffect(() => {
		// limpiar los datos cuando el componente se desmonte
		return () => {
			dispatch(setUrl(null));
			dispatch(setData(null));
		};
	}, [dispatch]);

	return (
		<div>
			{!results ? (

				<div className="desktop-view-SDR">
				<div id="reportContainerSDR">
					
				<div className="centerDS">
						{mensaje? <h1>{mensaje}</h1> : <CircularProgress />}
					</div>
				</div>
			</div>
			) : (
				<div className="desktop-view-SDR">
					<div id="reportContainerSDR">
						<PowerBIEmbed
							cssClassName="iframe"
							embedConfig={{
								type: 'report',
								tokenType: models.TokenType.Embed,
								accessToken: accessToken,
								embedUrl: embedUrl,
								id: id,
								pageView: 'actualSize',

								// Enable this setting to remove gray shoulders from embedded report
								settings: {
									background: models.BackgroundType.Transparent,
									filterPaneEnabled: false,
									navContentPaneEnabled: false,
								},
							}}
						/>
					</div>
				</div>
			)}
		</div>
	);
}

export default ViewBI;