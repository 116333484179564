import React from "react";
import { SvgIcon } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { editToolsIcon, copyIcon, cancelIcon, saveIcon, eyeIcon } from "@progress/kendo-svg-icons";

const RowDetails = ({ item, customClass }) => {
    return (
        <>
            <tr className={`renewalsAgentPanel-td ${customClass}`}>
                <td className="renewalsAgentPanel-td header" colSpan={4}>
                    Datos de contacto
                </td>
                <td className="renewalsAgentPanel-td header" colSpan={3}>
                    Tipo de gestion
                </td>
                <td className="renewalsAgentPanel-td header" colSpan={3}>
                    Detalles NC
                </td>
            </tr>
            <tr className={`renewalsAgentPanel-tr ${customClass}`}>
                <td className="renewalsAgentPanel-td" colSpan={4}>
                    {item.Data.nombreCliente}
                    <br />
                    {item.Data.cargo}
                    <br />
                    {item.Data.contacto}
                </td>
                <td className="renewalsAgentPanel-td" colSpan={3}>{item.Data.tipoGestion}</td>
                <td className="renewalsAgentPanel-td" colSpan={3}>
                    {
                        item.Data.aplicaNC == "No" ? "No Aplica" : <>
                            Tipo: {item.Data.tipoNC}
                            <br />
                            Motivo: {item.Data.motivoNC}
                            <br />
                            Valor: {item.Data.valorNC}
                        </>
                    }
                </td>
            </tr>
            <tr className={`renewalsAgentPanel-tr ${customClass}`}>
                <td className="renewalsAgentPanel-space" colSpan={10}></td>
            </tr>
        </>
    )
}
export { RowDetails }