import React, { useEffect } from 'react'
import DataSourceSelector from './DataSourceSelector'
import useAxios from '../../../../../hooks/useAxios'
import { baseURL } from '../../../../../../Config'
import { useSelector } from 'react-redux'
import { selectUrl } from '../../../../../../redux/viewBISlice'
import { selectCredencialesUsr } from '../../../../../../redux/credencialesReducer'
import useAxiosPost from '../../../../../../components/ViewCustomer/Hook/useAxiosPost'
import useAxiosPut from '../../../../../../HelpDesk/hooks/UseAxiosPut'

export const LinajeMain = ({setToast}) => {
  const{data, isLoading, error}=useAxios(`${baseURL}/api/v1/helpDesk/reading_databricks`)
  const UrlReport = useSelector(selectUrl);
	const email = useSelector(selectCredencialesUsr);
	const {
		data: dataFromReport,
		loading,
		error: errorDataFromReport,
		postData,
	} = useAxiosPost(`${baseURL}/api/v1/pbi/Reports/metadata`);
  useEffect(() => {
		postData({
			email: email,
			GetAtribute: {
				filter: {route: UrlReport},
				fields: ['lineage']},
		});
	}, [])
  return (
    <div><DataSourceSelector setToast={setToast}email={email}UrlReport={UrlReport} data={data}isLoading={isLoading} dataFromReport={dataFromReport} loadingDataFromReport={loading}/></div>
  )
}
