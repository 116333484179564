import React from 'react';
import './styles.css'
import { RiMedalLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

const CardGuest = ({number,title,content,link}) => {
    const imagen={
       1:<RiMedalLine style={{color:'black',width:'40px',height:'40px',marginBottom:'18px'}}/>,
       2:<img src="https://www.holcim.com.co/themes/custom/corporate_country/components/header/images/holcim_logo_color.svg" alt="logo Holcim"/>,
       3:<img src="https://logo.clearbit.com/mailchimp.com" alt="Mailchimp logo" />
    }
    const navigate=useNavigate();
    const handdle=()=>{
        navigate(link)
    }
    return (
        <div className="custom-card" onClick={handdle}>
            <div className="card-logo">
                {imagen[number]}
            </div>
            <h3 className="card-title">{title}</h3>
            <p className="card-description">
                {content}
            </p>
        </div>
    );
}

export default CardGuest;