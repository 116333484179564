import {useEffect, useMemo, useState} from 'react';
import {dataProcessing} from './TimeLine/DataProcessing';
import useAxios from '../../hooks/UseAxios';
import {Loader} from '@progress/kendo-react-indicators';
import {TextAreaComments} from './TimeLine/TimelineItem';
import './TimeLine/TimeLine.css';
import {SvgIcon} from '@progress/kendo-react-common';
import {clockIcon} from '@progress/kendo-svg-icons';
import { baseURL } from '../../../Config';

export const TimeLineHistory = ({ticketId, status,filterr=false}) => {
	let ticketToSend= parseInt(ticketId);
	const url = useMemo(
		() => `${baseURL}/api/v1/helpDesk/showlistComments/${ticketToSend}`,
		[ticketId]
	);
	const {data, isLoading, error} = useAxios(url);
	const [sortedEvents, setSortedEvents] = useState([]);
	useEffect(() => {
		const processData = dataProcessing(data, status,filterr);	
		setSortedEvents(processData);
	}, [data, status]);

	if (isLoading) {
		return (
			<div>
				<Loader
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%,-50%)',
					}}
					size="large"
					type={'converging-spinner'}
				/>
			</div>
		);
	}
	return (
		<div>
			{sortedEvents.map((grupo, indexGrupo) => (
				<div key={indexGrupo}>
					<h2>{grupo.title}</h2>
					<div className="rayita"></div>
					<div className="caja-cajita-comment">
						{grupo.items.map((comentario, indexComentario) => (
							<div
								key={indexComentario}
								className="cajita-comment comment-apear">
								<p>
									{comentario.commentOwner.name} -{' '}
									{comentario.commentOwner.mail}
								</p>
								<TextAreaComments content={comentario.comment} />
								<div style={{display: 'flex', justifyContent: 'space-between'}}>
									<div style={{display: 'flex', flexDirection: 'row'}}>
										<SvgIcon
											icon={clockIcon}
											size="xsmall"
											style={{marginTop: '3px'}}
										/>
										<p style={{marginLeft: '2px', fontStyle: 'italic'}}>
											{comentario.date}
										</p>
									</div>
									<div>
										<p style={{fontStyle: 'italic'}}>
											Tiempo transcurrido: {comentario.timeElapsedStatus} Hrs
										</p>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			))}
		</div>
	);
};
