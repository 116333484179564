import React from "react";

const ColumnNotaCredito = ({ item, index, editMode, editingData, setEditingData }) => {
    const renderInputField = (idNotaCredito) => (
        <input
            className='renewalsAgentPanelInput'
            value={idNotaCredito}
            onChange={(event) => {
                setEditingData((prevState) => ({
                    ...prevState,
                    [index]: {
                        ...prevState[index],
                        dynamicInputsData: {
                            ...prevState[index].dynamicInputsData,
                            tablero: {
                                ...prevState[index].dynamicInputsData.tablero,
                                idNotaCredito: event.target.value
                            }
                        }
                    }
                }));
            }}
        />
    );

    const getNotaCreditoData = (obj) => {
        if (obj.dynamicInputsData) {
            return {
                aplicaNC: obj.dynamicInputsData.tablero.aplicaNC,
                idNotaCredito: obj.dynamicInputsData.tablero.idNotaCredito
            };
        } else {
            return {
                aplicaNC: obj.Data.aplicaNC,
                idNotaCredito: obj.Data.idNotaCredito
            };
        }
    };

    const { aplicaNC, idNotaCredito } = getNotaCreditoData(item);
    const editIdNotaCredito = editingData[index]?.dynamicInputsData?.tablero?.idNotaCredito || '';

    return (
        <td className="renewalsAgentPanel-td">
            {aplicaNC === "No" ? "No Aplica" : (
                editMode[index] ? renderInputField(editIdNotaCredito) : idNotaCredito
            )}
        </td>
    );
};

export { ColumnNotaCredito };