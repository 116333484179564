import React, { useEffect } from 'react'
import ViewBI from '../reports/component/ViewBI'
import { useDispatch, useSelector } from 'react-redux';
import { selectCredencialesArea, selectCredencialesCompany, selectCredencialesdepartment, selectCredencialesRole, selectCredencialesUsr } from '../redux/credencialesReducer';
import { reportsAlianzas } from '../reports/db/reportsAlianzas';
import { setData, setUrl } from '../redux/viewBISlice';


export const Holcim = () => {
  const area=useSelector(selectCredencialesArea);
  const rol=useSelector(selectCredencialesRole);
	const username=useSelector(selectCredencialesUsr);
  const Company =useSelector(selectCredencialesCompany);
  const department =useSelector(selectCredencialesdepartment);
  const dispatch=useDispatch();
  useEffect(() => {
	  
		handleReport();
		return () => {
			dispatch(setData(null));
			dispatch(setUrl(null));
		};
	}, [])
  const handleReport = async () => {


		let result = 1; // 1 es el estado que representa que está cargando
		dispatch(setData(1));
		result = await reportsAlianzas("Alianzas/Holcim/General/*",department,Company,username,rol,area);

		dispatch(setData(result)); // Dispara de nuevo para cambiar el estado a 1, lo que significa que ya llegó la data
		dispatch(setUrl("Alianzas/Holcim/General/*"));

	};
  return (
    <div>
        <div style={{height:'70px',width:'80px'}}>
        <img src="https://www.holcim.com.co/themes/custom/corporate_country/components/header/images/holcim_logo_color.svg" alt="logo Holcim"/>
        </div>
        <ViewBI/>
    </div>
  )
}
