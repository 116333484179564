import {useDispatch, useSelector} from 'react-redux';
import {selectData, selectUrl} from '../../redux/viewBISlice';
import {forwardRef, useEffect, useState} from 'react';
import {infoReport} from '../db/InfoReport';
import {Loader} from '@progress/kendo-react-indicators';
import {xIcon} from '@progress/kendo-svg-icons';
import {SvgIcon} from '@progress/kendo-react-common';
import { ButtonRefresh } from './ControlCenter/ButtonRefresh';
import { IsAuthorized } from '../../components/auth/IsAuthorized';
import { selectCredencialesArea, selectCredencialesRole } from '../../redux/credencialesReducer';

import { Slice } from './ControlCenter/slices/MainSlice/Slice';
import { selecthistory, selectisOpenSeconDisplay, selectMenuWidth, setHistory, setLastUpdate, setMenuWidth, setOpenSeconDisplay } from '../../redux/ControlCenterReducer';
import { Button } from '@progress/kendo-react-buttons';
import { GoTab } from 'react-icons/go';
import { Dialog, DialogContent, Slide } from '@mui/material';


export const ControlCenter = ({open, onClose,ReportName}) => {
	const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
	const datarefresh = useSelector(selecthistory);
	const [dataLastRow, setDataLastRow] = useState({});
	const area=useSelector(selectCredencialesArea);
	const rol=useSelector(selectCredencialesRole);
	const [datasetId, setDatasetId] = useState({datasetId: '', requestId: '', type: ''});
	const [dataReportFabric, setDataReportFabric] = useState({reportName: 'Este reporte no se actualiza', reportId: 'f'});

	let dataReport = useSelector(selectData);
	let Menuwidth = useSelector(selectMenuWidth);
	const dispatch = useDispatch();
	const isOpenSeconDisplay = useSelector(selectisOpenSeconDisplay);

	const handleButtonClickOpen = () => {
		if (isOpenSeconDisplay) {
			dispatch(setMenuWidth(720));
		} else {
			dispatch(setMenuWidth(1290));
		}
		dispatch(setOpenSeconDisplay(!isOpenSeconDisplay));
	};

	useEffect(() => {
		const infoExtract = async () => {
			setIsLoadingRefresh(true);
			try {
				if (dataReport &&dataReport.reportConfig) {	
					setDatasetId(dataReport.reportConfig.datasetId);		
					let response = await infoReport(dataReport.reportConfig.datasetId);
					if (response !== 1 && response && response.value) {
						let refreshresults = JSON.stringify(response.value);
						refreshresults = JSON.parse(refreshresults).map(obj => ({ ...obj, expanded: false }));
						dispatch(setHistory(refreshresults))
						setDataReportFabric({'Nombre embedded ':dataReport.reportConfig.reportName, 'DatasetId ': dataReport.datasetId , 'reportId': dataReport.reportConfig.reportId});
					} else {
						dispatch(setHistory([]))
					}
				}
			} catch (error) {
				console.error('Error al obtener la información', error);
			}
			setIsLoadingRefresh(false);
		};
		if(open){
			infoExtract();
		}
	}, [open]);
	const expandChange = (event) => {
		let newData = datarefresh.map((item) => {
			if (item.id === event.dataItem.id) {
				return {
					...item,
					expanded: !event.dataItem.expanded
				};
			}
			return item;
		});
		dispatch(setHistory(newData));
	};
	useEffect(() => {
		let alldatalastRow;

		if (Array.isArray(datarefresh) && datarefresh.length > 0) {
		  alldatalastRow = datarefresh[0];

			const lastSixItems = datarefresh.slice(-6);
		  setDataLastRow({datasetId,requestId: alldatalastRow.requestId, type: alldatalastRow.status,lastSixItems});
		  dispatch(setLastUpdate(alldatalastRow))
		} else {

		}
	
	}, [datarefresh,datasetId])

	useEffect(() => {
	  
	
	  return () => {
		if (open) {
			setDataLastRow({});
			dispatch(setLastUpdate({datasetId:'',requestId: '', type: ''}))
			dispatch(setHistory([]))
		}
	  }
	}, [open])
	
	const Transition = forwardRef(function Transition(props, ref) {
		return <Slide direction="left" ref={ref} {...props} />;
	  });

	const CustomItem = (props) => {

			return (
				<>
					<span />
					<div className="item-descr-wrap">
					
						<div style={{ display: 'flex', marginLeft: '13px', justifyContent: 'space-between', width: '98%' }}>
							<div style={{ display: 'flex',  justifyContent: 'space-between'}}>
							<Button fillMode={'flat'} onClick={handleButtonClickOpen}>
								<GoTab className={`iconOpen ${isOpenSeconDisplay ? '' : 'rotated'}`} />
							</Button>
								<h2>{props.text}</h2>
								{IsAuthorized('ControlCenter',area,rol)?(<ButtonRefresh dataLastRow={dataLastRow}/>):(null)}
							</div>
							<SvgIcon icon={xIcon} size="xlarge" className={'p-hover'} onClick={()=>{props.onSelectClose()}}/>	
						</div>
						{isLoadingRefresh ? (
							<div>
								<Loader
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%,-50%)',
									}}
									size="large"
									type={'converging-spinner'}
								/>
								<span>
									<p>Cargando Información...</p>
								</span>
							</div>
						) : (
							<div>
								<Slice isOpenSeconDisplay={isOpenSeconDisplay} dataReportFabric={dataReportFabric} datarefresh={datarefresh} datasetId={datasetId} expandChange={expandChange}/>
							</div>
						)}
					</div>
				</>
			);
	};

	return (
		<Dialog
		  open={open}
		  onClose={onClose}
		//   TransitionComponent={Transition}
		  PaperProps={{
			sx: {
			  position: 'fixed',
			  right: 0,
			  margin: 0,
			  width: `${Menuwidth}px`,
			  height: '100%',
			  maxWidth: 'none', // Evita que se aplique el ancho máximo predeterminado
			},
		  }}
		>
			<DialogContent><CustomItem
		  text={ReportName}
		  onSelectClose={onClose}
		  /></DialogContent>
		  
		  
		</Dialog>
	  );
};
