import axios from 'axios';
// Initialize variables to find the closest date

const getIncidentByRFC = async (rfc, callback) => {
    try {
        // Fetch data from the API
        const response = await axios.get(`/api/siignalGetBaseRenovacionesMX/${rfc}`);
        const data = response.data;
        callback(data);
    } catch (err) {
        // Improved error handling
        console.error('Error fetching data:', err);
        alert('Se ha presentado un error al obtener los datos.');
    }
};

const getIncidents = async(callback) => {
    await axios.get('/api/mxPaymentPromises').then(res => {
        let resultData = res.data;
        callback(resultData);
    }).catch(err => {
        alert("Se ha presentado un error");
    });
}
const get_Left_Days = async (callback) => {
    try {
        const response = await axios.get('/api/mxPayment_left_days');
        // Asegúrate de que la respuesta tenga la estructura esperada
        if (response.data.length > 0 && response.data[0].Mexico) {
            let resultData = response.data[0].Mexico;
            console.log(resultData)
            // Verifica que resultData contenga los valores numéricos
            const leftDaysToShow_month = parseInt(resultData.leftDaysToShow_month); // Asegúrate de que esto sea un número
            const leftDaysToShow_year = parseInt(resultData.leftDaysToShow_year);   // Asegúrate de que esto sea un número
            callback({ leftDaysToShow_month, leftDaysToShow_year });
        } else {
            throw new Error('La respuesta de la API no es válida');
        }
    } catch (error) {
        console.error('Error al obtener los días restantes:', error);
        alert("Se ha presentado un error");
    }
};

const saveNewIncident = (incident) => {
    axios.post('/api/mxPaymentPromises', incident).then(res => {
        alert("Incidente Guardado")
    }).catch(err => {
        console.log(err)
        alert(err.response.data.message)
    });
}

const saveEditIncident = (incident) => {
    axios.patch(`/api/mxPaymentPromises/${incident.codigo}`, incident).then(res => {
        console.log("Incident saved")
    }).catch(err => {
        alert(err.response.data.message)
    });
}

const addClientToIncident = (incident, client) => {
    axios.put(`/api/mxPaymentPromises/${incident.codigo}`, client).then(res => {
        console.log("Incident saved")
    }).catch(err => {
        alert(err.response.data.message)
    });
}

export { getIncidentByRFC, getIncidents, saveNewIncident, saveEditIncident, addClientToIncident, get_Left_Days }