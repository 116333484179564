import { Button } from "@progress/kendo-react-buttons";
import axios from "axios";
import { useEffect, useState } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { BiLoaderAlt } from "react-icons/bi";
import { IsAuthorized } from "../../../../../components/auth/IsAuthorized";
import { useSelector } from "react-redux";
import { selectCredencialesArea, selectCredencialesRole, selectCredencialesUsr } from "../../../../../redux/credencialesReducer";
import { baseURL } from "../../../../../Config";
import { handleDownload } from "../../../../../HelpDesk/components/InfoTicket/bodyTicket/handleDownload";

const DownloadReport = ({reportId,name='Desconocido',setToast}) => {
  const [isDownload, setIsDownload] = useState(false);
  const area = useSelector(selectCredencialesArea);
  const rol=useSelector(selectCredencialesRole);
  const user=useSelector(selectCredencialesUsr);
  const IsAllowed=IsAuthorized('HelpDesk-dataPartner',area,rol )
  
    const fetchData = async () => {
      try {
        const response = await axios.post(`${baseURL}/api/v1/FileManagement/get_uid`, {
          "user": user,
          "reportName": `${name}.pbix`
        });
        if (Array.isArray(response.data) && response.data.length > 0 && response.data[0].uid) {
          handleDownload(
            response.data[0].uid,
            setToast,
            'solicitud de Reporte',
            `${name}.pbix`,
            ".pbix",
            setIsDownload
          );
        } else {
           setToast({ snackbarOpen: true, message: 'Este reporte aún no está disponible', type: 'error' });
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
        if(error.response?.status===401){
          setToast({ snackbarOpen: true, message: 'Usted no está autorizado', type: 'error' });
        }
        else if(error.response?.status===403){
          setToast({ snackbarOpen: true, message: 'Ya no puede descargar más reportes por el día de hoy', type: 'error' });
        }else{
          setToast({ snackbarOpen: true, message: 'Error al descargar el archivo', type: 'error' });
        }
      }
    };
    const handleClick = async () => {
      await fetchData();
    };
  
  
    
    return (
      <div>
        {IsAllowed && (
          <>
            <Button
              style={{ width: '25px', height: '25px' }}
              onClick={handleClick}
              disabled={isDownload}
            >
              {!isDownload ? <MdOutlineFileDownload /> : <BiLoaderAlt className="spinner" />}
            </Button>
            {!isDownload ? null : (
              <span className="messageWaitingDownload" style={{ marginLeft: '6px' }}>
                Espere un momento
              </span>
            )}
          </>
        )}
      </div>
    );

  }
export default DownloadReport;