import {Button} from '@progress/kendo-react-buttons';
import {Input} from '@progress/kendo-react-inputs';
import React, {useState} from 'react';
import {FaArrowLeft} from 'react-icons/fa';
import './style.css';

import axios from 'axios';
import { baseURL } from '../../../../../../Config';
import { useSelector } from 'react-redux';
import { selectCredencialesUsr } from '../../../../../../redux/credencialesReducer';
export const DeleteReportsMenu = ({setToast,name,reportId,datasetId,onDelete}) => {
	const [step, setStep] = useState(1);
	const [reportName, setReportName] = useState(name);
	const user=useSelector(selectCredencialesUsr);
	const [confirmationName, setConfirmationName] = useState('');

	const handleActionClick = () => {

		setStep(2);
	};

	const handleConfirm = async () => {
		if (reportName === confirmationName) {
			await axios.delete(`${baseURL}/api/v1/FileManagement/deleteReport`, {
				params: {
				  report_id: reportId,
				  dataset:datasetId,
				  user: user,
				  name: name
				}
			  })
				.then(response => {
					
					setToast({snackbarOpen:true,message:"report deleted successfully",type:'info'})
					// Aquí puedes agregar la lógica adicional después de eliminar el reporte
				})
				.catch(error => {
					setToast({snackbarOpen:true,message:"An error occurred, action was not successful.",type:'error'})
					console.error('There was an error deleting the report!', error);
				});
			// Aquí puedes agregar la lógica para eliminar el reporte o la ruta
		} else {
			setToast({snackbarOpen:true,message:"El nombre del reporte no coincide. Inténtalo de nuevo.",type:'error'})
		}
		onDelete();
	};

	return (
		<div>
			{step === 1 && (
				<div className="menuUnoDelete">
					<h3>¿Desea eliminar el reporte?</h3>
					<div className='btncontmenuUnoDelete'>
						<Button onClick={() => handleActionClick()}>
							Eliminar Reporte y Ruta
						</Button>
					</div>
				</div>
			)}
			{step === 2 && (
				
				<div className="menuDosDelete">
					<div style={{display:'flex',gap:'15px',justifyContent:'start'}}><FaArrowLeft onClick={() => setStep(1)} style={{cursor: 'pointer',height:'40px',width:'20px'}} />
					<h3>¿Está seguro?</h3></div>
					<p>Escriba el nombre del reporte para confirmar su decisión:</p>
					<div style={{display:'flex', gap:'15px'}}><Input
						type="text"
						value={confirmationName}
						onChange={(e) => setConfirmationName(e.target.value)}
						placeholder="Nombre del reporte"
						onPaste={(e) => e.preventDefault()}
					/>
					<Button onClick={handleConfirm}>Confirmar</Button>
					</div>
				</div>
			)}
		</div>
	);
};
