import {useCallback, useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {
	Grid,
	GridColumn as Column,
	GridToolbar,
	GridColumnMenuSort,
	GridColumnMenuFilter,
} from '@progress/kendo-react-grid';
import {Button} from '@progress/kendo-react-buttons';
import {MyCommandCell} from './GridMethods';
import {
	convertToISODate,
	deleteItem,
	getItems,
	insertItem,
	MonthsDrowpdown,
	AliadosDrowpdown,
	PhoneNumberInput,
	TiposdeRegistrosDrowpdown,
	updateItem,
	ValidatedInputCell,
} from './GridMethodsLocal';
import {process} from '@progress/kendo-data-query';
import {useSelector} from 'react-redux';
import {selectCredencialesUsr} from '../../redux/credencialesReducer';
import CustomSnackbar from '../Notification/SnackBar';
import SearchBar from '../searchBar/Searchbar';
const editField = 'inEdit';
const CommandCell = (props) => {
	const {edit, remove, add, discard, update, cancel, editField} = props;
	return (
		<MyCommandCell
			{...props}
			edit={edit}
			remove={remove}
			add={add}
			discard={discard}
			update={update}
			cancel={cancel}
			editField={editField}
		/>
	);
};
export const ColumnMenu = (props) => {
	return (
		<div>
			<GridColumnMenuSort {...props} />
			<GridColumnMenuFilter {...props} />
		</div>
	);
};

const loadingPanelMarkup = (
	<div className="k-loading-mask">
		<span className="k-loading-text">Loading</span>
		<div className="k-loading-image" />
		<div className="k-loading-color" />
	</div>
);
const LoadingPanel = (props) => {
	const {gridRef} = props;
	const gridContent =
		gridRef.current && gridRef.current.querySelector('.k-grid-content');
	return gridContent
		? ReactDOM.createPortal(loadingPanelMarkup, gridContent)
		: loadingPanelMarkup;
};

export const GridTemplate = ({
	columns,
	belonging,
	params,
	IsThereSearchBar,
  email
}) => {
	const [isloading, setLoading] = useState(true);
	const gridRef = useRef(null);
	const [data, setData] = useState([]);
	const [dataOriginal, setDataOriginal] = useState([]);
	const [take, setTake] = useState();
	const [skip, setSkip] = useState(0);
	const [sort, setSort] = useState([]);
	const [group, setGroup] = useState([]);
	const [filter, setFilter] = useState(null);
	const [error, setError] = useState(null);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackOperation, setSnackOperation] = useState({
		type: '',
		message: '',
	});
	const handleSearch = (results) => {
		setData(results);
	};
	const dataState = {
		take,
		skip,
		sort,
		group,
		filter,
	};

	const onDataStateChange = useCallback(
		(event) => {
			setTake(event.dataState.take);
			setSkip(event.dataState.skip);
			setSort(event.dataState.sort);
			setGroup(event.dataState.group);
			setFilter(event.dataState.filter);
		},
		[setTake, setSkip, setSort, setGroup]
	);

	useEffect(() => {
		const fetchData = async () => {
      setLoading(true);
			const response = await getItems(email, belonging);
			if (response.error) {
				setError(response.error);
			} else {
				setData(response.data);
				setDataOriginal(response.data);
			}
			setLoading(false);
		};

		if (email) {
			fetchData();
		}
	}, [email, belonging]);

	// modify the data in the store, db etc
	const remove = async (dataItem) => {
		const response = await deleteItem(dataItem);
		if (response.error) {
			setError(response.error);
		} else {
			setData(data.filter((d) => d.KEY !== dataItem.KEY));
			setDataOriginal(dataOriginal.filter((d) => d.KEY !== dataItem.KEY));
			setSnackOperation({
				type: 'success',
				message: 'Record deleted successfully',
			});
			handleOpenSnackbar();
		}
	};

	const validateRequiredFields = (dataItem, requiredFields = []) => {
		for (const field of requiredFields) {
			const value = dataItem[field.field];
			if (!value || value.trim() === '') {
				setSnackOperation({
					type: 'error',
					message: field.textMessage,
				});
				handleOpenSnackbar();
				return false;
			}
			if ('minLength' in field && value.length < field.minLength) {
				setSnackOperation({
					type: 'error',
					message: `${field.field} debe tener al menos ${field.minLength} caracteres`,
				});
				handleOpenSnackbar();
				return false;
			}
			if ('maxLength' in field && value.length > field.maxLength) {
				setSnackOperation({
					type: 'error',
					message: `${field.field} no debe tener más de ${field.maxLength} caracteres`,
				});
				handleOpenSnackbar();
				return false;
			}
		}
		return true;
	};

	const add = async (dataItem) => {
		if (!validateRequiredFields(dataItem, params?.requiredFields)) {
			return;
		}

		dataItem.gridSchemas = belonging;
		dataItem.userEmail = email;
		dataItem.inEdit = false;
		const response = await insertItem(dataItem);
		if (response.error) {
			setSnackOperation({
				type: 'error',
				message: response.error,
			});
			handleOpenSnackbar();
			setError(response.error);
		} else {
			const newData = [...data];
			newData.splice(0, 1);
			const FixDate = convertToISODate([response.data, ...newData]);
			setSnackOperation({
				type: 'success',
				message: 'Record added successfully',
			});
			handleOpenSnackbar();
			setData(FixDate);
			setDataOriginal(FixDate);
		}
	};

	const update = async (dataItem) => {
		delete dataItem.inEdit;
		const response = await updateItem(dataItem);
		if (response.error) {
			setError(response.error);
		} else {
			const newData = data.map((d) =>
				d.KEY === dataItem.KEY ? response.data : d
			);
			setData(convertToISODate(newData));
			setDataOriginal(convertToISODate(newData));
		}
	};

	// Local state operations
	const discard = () => {
		const newData = [...data];
		newData.splice(0, 1);
		setData(newData);
	};
	const cancel = (dataItem) => {
		setData(
			data.map((item) =>
				item.KEY === dataItem.KEY
					? {
							...item,
							inEdit: false,
					  }
					: item
			)
		);
	};
	const enterEdit = (dataItem) => {
		setData(
			data.map((item) =>
				item.KEY === dataItem.KEY
					? {
							...item,
							inEdit: true,
					  }
					: item
			)
		);
	};
	const itemChange = (event) => {
		const newData = data.map((item) =>
			item.KEY === event.dataItem.KEY
				? {
						...item,
						[event.field || '']: event.value,
				  }
				: item
		);
		setData(newData);
	};
	const addNew = () => {
		const newDataItem = {
			inEdit: true,
			Discontinued: false,
		};
		setData([newDataItem, ...data]);
	};
	let processedData = process(data, dataState);
	const commandCellProps = {
		edit: enterEdit,
		remove: remove,
		add: add,
		discard: discard,
		update: update,
		cancel: cancel,
		editField: editField,
	};

	const handleOpenSnackbar = () => {
		setSnackbarOpen(true);
	};

	const handleCloseSnackbar = () => {
		setSnackbarOpen(false);
	};
	return (
		<div ref={gridRef}>
			{isloading ? <LoadingPanel gridRef={gridRef} /> : null}
			<CustomSnackbar
				open={snackbarOpen}
				handleClose={handleCloseSnackbar}
				message={snackOperation.message}
				severity={snackOperation.type}
			/>
			{IsThereSearchBar ? (
				<>
					<SearchBar
						data={dataOriginal}
						searchFields={params.searchFields}
						onSearch={handleSearch}
					/>
					<br />
				</>
			) : null}
			<Grid
				data={processedData}
				onItemChange={itemChange}
				onDataStateChange={onDataStateChange}
				editField={editField}
				resizable={true}>
				<GridToolbar>
					<Button title="Add new" type="button" onClick={addNew}>
						Add new
					</Button>
				</GridToolbar>
				{columns.map((column) => (
					<Column
						field={column.field}
						title={column.title}
						editable={column.editable}
						editor={column.editor ? column.editor : null}
						cell={column.cell ? dictCellOptions[column.cell] : null}
						format={column.format}
						columnMenu={column.columnMenu ? ColumnMenu : undefined}
						width={column.width}
					/>
				))}
				<Column
					cell={(props) => (
						<CommandCell
							{...props}
							{...commandCellProps}
							currentDate={new Date()}
							isAuthorized={false}
							paramsGrid={params}
						/>
					)}
					width="145px"
				/>
			</Grid>
		</div>
	);
};

const dictCellOptions = {
	MonthsDrowpdown: (props) => <MonthsDrowpdown {...props} />,
	AliadosDrowpdown: (props) => <AliadosDrowpdown {...props} />,
	TiposdeRegistrosDrowpdown: (props) => (
		<TiposdeRegistrosDrowpdown {...props} />
	),
	ValidatedInputCell: (props) => <ValidatedInputCell {...props} />,
	PhoneNumberInput: (props) => <PhoneNumberInput {...props} />,
};
