import * as React from 'react';
import '../../components/status-card/statuscard.css';
import {AppTNB} from '../Training/appTNB';
import {
	Toolbar,
	ToolbarItem,
	ToolbarSeparator,
	ToolbarSpacer,
	Button,
} 
from '@progress/kendo-react-buttons';
import {Dialog} from '@progress/kendo-react-dialogs';
import {TnbFormularioUpload} from '../../components/TNB/tnbFormularioUpload';
import {TnbTemas} from '../../components/TNB/topics';
import {TnbFeedback} from '../../components/TNB/feedback';
import {useMsal} from '@azure/msal-react';
import { alignSelfEndAltIcon, homeIcon, slidersIcon, tellAFriendIcon, uploadIcon } from '@progress/kendo-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCredencialesArea, selectCredencialesName, selectCredencialesRole } from '../../redux/credencialesReducer';
import { IsAuthorized } from '../../components/auth/IsAuthorized';

export const ReportsTNBApp = () => {
	const [visible, setVisible] = React.useState(false);
	const [visibleReports, setVisibleReports] = React.useState(false);
	const [visibleTemas, setVisibleTemas] = React.useState(false);
	const [visibleFeedback, setVisibleFeedback] = React.useState(false);
	const [visibleHome, setVisibleHome] = React.useState(true);
	const {instance, accounts} = useMsal();
	const tnbUser = accounts[0] && accounts[0].username;
	let navigate=useNavigate();
	const name=useSelector(selectCredencialesName);
    const area = useSelector(selectCredencialesArea);
    const rol=useSelector(selectCredencialesRole);
    const isAllowed={
		Admin: IsAuthorized('TNB-admin',area,rol ),
		Entrenador: IsAuthorized('TNB-entrenador',area,rol ),
	}



	const toggleDialog = () => {
		setVisible(!visible);
	};

	const toggleTemas = () => {
		if (visibleReports === true) {
			setVisibleReports(!visibleReports);
			setVisibleTemas(!visibleTemas);
		} else if (visibleFeedback === true) {
			setVisibleFeedback(!visibleFeedback);
			setVisibleTemas(!visibleTemas);
		} else if (visibleHome === true) {
			setVisibleHome(!visibleHome);
			setVisibleTemas(!visibleTemas);
		}
	};
	const toggleHome = () => {
		if (visibleTemas === true) {
			setVisibleTemas(!visibleTemas);
			setVisibleHome(!visibleHome);
		} else if (visibleReports === true) {
			setVisibleReports(!visibleReports);
			setVisibleHome(!visibleHome);
		} else if (visibleFeedback === true) {
			setVisibleFeedback(!visibleFeedback);
			setVisibleHome(!visibleHome);
		}
	};
	const toggleFeedback = () => {
		if (visibleTemas === true) {
			setVisibleTemas(!visibleTemas);
			setVisibleFeedback(!visibleReports);
		} else if (visibleReports === true) {
			setVisibleReports(!visibleReports);
			setVisibleFeedback(!visibleFeedback);
		} else if (visibleHome === true) {
			setVisibleHome(!visibleHome);
			setVisibleFeedback(!visibleFeedback);
		}
	};
	const onDeleteData = () => {
		alert('Data deleted.');
		setVisible(!visible);
	};

	let view;
	
	if(isAllowed.Admin) {
		view = (
			<Toolbar>
				<ToolbarItem>TNB APP</ToolbarItem>
				<ToolbarSpacer />
				<ToolbarSpacer />
				<ToolbarItem>
					<Button svgIcon={homeIcon} title="Home" onClick={toggleHome}>
						Inicio
					</Button>
				</ToolbarItem>
				<ToolbarItem>
					<Button svgIcon={slidersIcon} title="Copy" onClick={toggleTemas}>
						{' '}
						Temas | Proyectos
					</Button>
				</ToolbarItem>
				<ToolbarItem>
					<Button
						svgIcon={alignSelfEndAltIcon}
						title="Copy"
						onClick={() => navigate('/LobbyReports/Reportes%20por%20Área%2FCustomer%20Success%2FOnboarding%20y%20Training%2FTNB')}>
						{' '}
						Reportes
					</Button>
				</ToolbarItem>
				<ToolbarItem>
					<Button svgIcon={tellAFriendIcon} title="Copy" onClick={toggleFeedback}>
						{' '}
						Feedback
					</Button>
				</ToolbarItem>
				<ToolbarSeparator />
				<ToolbarItem>
					<Button onClick={toggleDialog} svgIcon={uploadIcon}>
						Asistencias
					</Button>
					{visible && (
						<Dialog
							title={'Cargas Masivas de Información'}
							onClose={toggleDialog}
							width={350}
							height={600}>
							<div>
								<TnbFormularioUpload />
							</div>
						</Dialog>
					)}
				</ToolbarItem>
			</Toolbar>
		);
	} else if (isAllowed.Entrenador) {
		view = (
			<Toolbar>
				<ToolbarItem>TNB APP</ToolbarItem>
				<ToolbarSpacer />
				<ToolbarSpacer />
				<ToolbarItem>
					<Button svgIcon={homeIcon} title="Home" onClick={toggleHome}>
						Inicio
					</Button>
				</ToolbarItem>
				<ToolbarItem>
					<Button
						svgIcon={alignSelfEndAltIcon}
						title="Copy"
						onClick={() => navigate('/LobbyReports/Reportes%20por%20Área%2FCustomer%20Success%2FOnboarding%20y%20Training%2FTNB')}>
						{' '}
						Reportes
					</Button>
				</ToolbarItem>
				<ToolbarItem>
					<Button svgIcon={tellAFriendIcon} title="Copy" onClick={toggleFeedback}>
						{' '}
						Feedback
					</Button>
				</ToolbarItem>
				<ToolbarSeparator />
				<ToolbarItem>
					<Button onClick={toggleDialog} svgIcon={uploadIcon}>
						Asistencias
					</Button>
					{visible && (
						<Dialog
							title={'Cargas Masivas de Información'}
							onClose={toggleDialog}
							width={350}
							height={600}>
							<div>
								<TnbFormularioUpload />
							</div>
						</Dialog>
					)}
				</ToolbarItem>
			</Toolbar>
		);
	}
	if(!isAllowed.Admin && !isAllowed.Entrenador) {
		return(
			<div id="reportContainerSDR">
					
			<div className="centerDS">
				<h1 className='gray'>No tienes permisos para acceder a esta página</h1>
				</div>
			</div>
		);
	}
	return (
		<>
			<h1 className="page-header-reports">TNB</h1>
			<div id="reportContainer">
				{view}
				<br />
				{visibleHome && (
					<AppTNB isAllowed={isAllowed} userValue={tnbUser} />
				)}

				{visibleTemas && <TnbTemas />}
				{visibleFeedback && <TnbFeedback />}
			</div>
		</>
	);
};
