import * as React from 'react';
import {Button} from '@progress/kendo-react-buttons';
import {Grid, Column, ColumnMenu} from '../grid';
import {CircularProgress} from '@mui/material';
import {
	FullNameCell,
	FlagCell,
	OnlineCell,
	TrainerNameCell,
	StartDateCell,
	EndDateCell,
	TypeCell,
	AreaCell,
} from '../GridCells';

import axios from 'axios';
import {DetailTrainingDialog} from '../DetailDialog/DetailTrainingDialog';
import {useDispatch, useSelector } from 'react-redux';
import { selectDetalleCapacitacion, setDetalleCapacitacion, setAllStatusCapacitacion, selectListadeCapacitaciones, getListaDeCapacitaciones, selectIsLoadingCapacitaciones } from '../../../redux/TNBSlice';
import { hyperlinkOpenSmIcon } from '@progress/kendo-svg-icons';
import { baseURL } from '../../../Config';




export const TnbGruposTable = (props) => {
	const results=useSelector(selectDetalleCapacitacion)
	
	const isloading=useSelector(selectIsLoadingCapacitaciones);
	const [isloadingDetails, setLoadingDetails] = React.useState(false);
	const [trainingCode, setTrainingCode] = React.useState([]);
	const [visibleDetails, setVisibleDetails] = React.useState(false);

  	const dispatch = useDispatch();
	const data=useSelector(selectListadeCapacitaciones)
	
	

	const toggleDialog = () => {
		setVisibleDetails(!visibleDetails);
	};

	React.useEffect(() => {
		dispatch(getListaDeCapacitaciones())
	}, [dispatch]);

	

	const onClickSeeDetails = async (props) => {
		try {
			setTrainingCode(props.dataItem.nombreCapacitacion);
			setVisibleDetails(!visibleDetails);			
			const response = await axios.get(`${baseURL}/api/v1/TNB/Training?id=${props.dataItem.nombreCapacitacion}`);
			dispatch(setDetalleCapacitacion(response.data))
			//acá trae todo el docmuento de una capacitación, mediante el nombre de la capacitación
			
		} catch (error) {
			console.error("Error al obtener los detalles:", error);

		}
		
		
		try {
			const responseTopicEmployeeStatus = await axios.get(`${baseURL}/api/v1/TNB/Participantes/Estados?id=tiposEstadoParticipantes`);
			const testTopicEmployeeStatus = responseTopicEmployeeStatus.data;
			dispatch(setAllStatusCapacitacion(testTopicEmployeeStatus));
		} catch (error) {
			console.error('Error al obtener los datos:', error);
		}
		setLoadingDetails(!isloadingDetails);
		
		
	}
	
	const MyCommandCell = (props1) => {
		return (
			<td style={{ textAlign: 'center' }}>
				<Button
					size={'small'}
					themeColor={'primary'}
					style={{fontSize: '12px'}}
					svgIcon={hyperlinkOpenSmIcon}
					onClick={() => onClickSeeDetails(props1)}
				></Button>
			</td>
		);
	};
	

	if (isloading) {
		return (
			<div className="desktop-view-SDR">
				<div id="reportContainerSDR">
					<div className="centerDS">
						<CircularProgress />
					</div>
				</div>
			</div>
		);
	} else
		return (
			<div>
				<div>
					<Grid
						data={data}
						style={{height: 700}}
						onRowClick={onClickSeeDetails}>
						<Column
							field={'nombreCapacitacion'}
							title={'Nombre Capacitación'}
							columnMenu={ColumnMenu}
							width={300}
							cell={FullNameCell}
						/>
						<Column
							field={'fechaIncioCapacitacion'}
							title={'Inicio'}
							columnMenu={ColumnMenu}
							width={110}
							cell={StartDateCell}
						/>
						<Column
							field={'fechaFinCapacitacion'}
							title={'Fin'}
							columnMenu={ColumnMenu}
							width={110}
							cell={EndDateCell}
						/>
						<Column
							field={'estado'}
							title={'Estado'}
							columnMenu={ColumnMenu}
							width={100}
							cell={OnlineCell}
						/>
						<Column
							field={'tipoCapacitacion'}
							title={'Tipo'}
							columnMenu={ColumnMenu}
							width={100}
							cell={TypeCell}
						/>
						<Column
							field={'nombreEntrenador'}
							title={'Entrenador'}
							columnMenu={ColumnMenu}
							width={220}
							cell={TrainerNameCell}
						/>
						<Column
							field={'pais'}
							title={'País'}
							columnMenu={ColumnMenu}
							width={80}
							cell={FlagCell}
						/>
						<Column
							field={'area'}
							title={'Área'}
							columnMenu={ColumnMenu}
							width={130}
							cell={AreaCell}
						/>
						<Column cell={MyCommandCell} title={'Detalles'} width={80} />
					</Grid>
					{visibleDetails && (
						<DetailTrainingDialog
							toggleDialog={toggleDialog}
							trainingCode={trainingCode}
						/>
					)}
				</div>
			</div>
		);
};

