import * as React from 'react';
import { Form, FormElement, Field } from '@progress/kendo-react-form';
import { FormMaskedTextBox, FormTextArea } from './form-components';
import { AutoComplete, DropDownList } from '@progress/kendo-react-dropdowns';
import { Label, Error, Hint, FloatingLabel } from '@progress/kendo-react-labels';
import { TextArea } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import { TileLayout } from '@progress/kendo-react-layout';
import { baseURL } from '../../Config';





export const FeedbackForm = () => {
    const [activeTrainings, setActiveTrainings] = React.useState([]);
    const [isLoadingActiveTrainings, setIsLoadingActiveTrainings] = React.useState(true);
    const [activeTrainingParticipants, setActiveTrainingParticipants] = React.useState([]);
    const [isLoadingActiveTrainingParticipants, setIsLoadingActiveTrainingParticipants] = React.useState(true);
    const [state, setState] = React.useState();
    const [participantFeedback, setParticipantFeedback] = React.useState();
    const feedbackUser = sessionStorage.getItem('usr')
    const feedbackDate = new Date()

    React.useEffect(() => {
        const loadGet = async () => {
            const responseActiveTrainings = await axios.get(`${baseURL}/api/v1/TNB/ActiveTrainings`);
            const testActiveTrainings = responseActiveTrainings.data
            setActiveTrainings(testActiveTrainings)
            setIsLoadingActiveTrainings(false);
        }
        loadGet();
    }, []);
    React.useEffect(() => {
        const loadGet = async () => {
            const responseActiveTrainingParticipants = await axios.get(`${baseURL}/api/v1/TNB/ActiveTrainings/Participants?id=${state}`);
            const testActiveTrainingParticipants = responseActiveTrainingParticipants.data
            setActiveTrainingParticipants(testActiveTrainingParticipants)
            setIsLoadingActiveTrainingParticipants(false);
        }
        loadGet();
    }, [state]);

    const handleChange = (event) => {
        setState(event.target.value.nombreCapacitacion);

    };

    const handleChangeActiveTrainingParticipants = (event) => {

        setParticipantFeedback(event.target.value.email);

    };





    const ActiveTrainingParticipantsDropDownList = () => {
        return (
            <>
                <Label>
                    Selecciona una capacitación:
                </Label>
                <DropDownList
                    data={activeTrainings}
                    onChange={handleChange}
                    value={activeTrainings.find((d) => d.nombreCapacitacion === state)}
                    textField={'nombreCapacitacion'} />

                {state === undefined ? null :
                    <>
                        <Label style={{ marginTop: '20px' }}>
                            Selecciona un participante:
                        </Label>
                        <DropDownList
                            data={activeTrainingParticipants}
                            onChange={handleChangeActiveTrainingParticipants}
                            value={activeTrainingParticipants.find((c) => c.email === participantFeedback)}
                            textField={'nombreParticipante'} />
                    </>
                }
            </>
        );
    }

    const FeedbackFields = () => {
        const [valueFortalezas, setValueFortalezas] = React.useState("");
        const [valueOportunidades, setValueOportunidades] = React.useState("");
        const [valueComentariosParticipantes, setValueComentariosParticipantes] = React.useState("");
        const [valueCompromisos, setValueCompromisos] = React.useState("");
        const [valueComentariosFinales, setValueComentariosFinales] = React.useState("");

        const handleChangeFortalezas = (e) => {
            setValueFortalezas(e.value);
        };
        const handleChangeOportunidades = (e) => {
            setValueOportunidades(e.value);
        };
        const handleChangeComentariosParticipantes = (e) => {
            setValueComentariosParticipantes(e.value);
        };
        const handleChangeCompromisos = (e) => {
            setValueCompromisos(e.value);
        };
        const handleChangeComentariosFinales = (e) => {
            setValueComentariosFinales(e.value);
        };

        const documentoFeedback = {
            fortalezas: valueFortalezas,
            oportunidades: valueOportunidades,
            comentariosParticipante: valueComentariosParticipantes,
            compromisos: valueCompromisos,
            comentariosFinales: valueComentariosFinales,
            idParticipante: participantFeedback,
            idCapacitacion: state,
            feedbackProvider: feedbackUser,
            feedbackDate: feedbackDate

        }

        const handleSubmit = (e) => {
            axios.post(`${baseURL}/api/v1/TNB/Feedback`, documentoFeedback)
            setValueFortalezas("");
            setValueOportunidades("");
            setValueComentariosParticipantes("");
            setValueCompromisos("");
            setValueComentariosFinales("");
            setState();
            setParticipantFeedback();
        }
        return (
            <>
                <Label>
                    Fortalezas
                </Label>
                <TextArea id='fortalezas' name='fortalezas' rows={3} value={valueFortalezas} onChange={handleChangeFortalezas} />
                <Label style={{ marginTop: '10px' }}>
                    Oportunidades
                </Label>
                <TextArea id='oportunidades' name='oportunidades' rows={3} value={valueOportunidades} onChange={handleChangeOportunidades} />
                <Label style={{ marginTop: '10px' }}>
                    Comentarios del participante
                </Label>
                <TextArea id='comentariosParticipante' name='comentariosParticipantes' rows={3} value={valueComentariosParticipantes} onChange={handleChangeComentariosParticipantes} />
                <Label style={{ marginTop: '10px' }}>
                    Compromisos
                </Label>
                <TextArea id='compromisos' name='compromisos' rows={3} value={valueCompromisos} onChange={handleChangeCompromisos} />
                <Label style={{ marginTop: '10px' }}>
                    Comentarios Finales
                </Label>
                <TextArea id='comentariosFinales' name='comentariosFinales' rows={3} value={valueComentariosFinales} onChange={handleChangeComentariosFinales} />
                <Button style={{ marginTop: '10px' }} onClick={handleSubmit}> Guardar </Button>
            </>

        )
    }

    const tiles = [
        {
            defaultPosition: {
                col: 1,
                colSpan: 6,
                rowSpan: 1,
            },
            header: "Selecciona una capacitación",
            body: <ActiveTrainingParticipantsDropDownList />,
            resizable: false,
            reorderable: false
        },
        {
            defaultPosition: {
                col: 7,
                colSpan: 6,
                rowSpan: 1,
            },
            header: "Información del participante",
            body: "informacion del participante",
            resizable: false,
            reorderable: false
        },
        {
            defaultPosition: {
                col: 1,
                colSpan: 12,
                row: 2,
                rowSpan: 5,
            },
            header: "Feedback del participante",
            body: <FeedbackFields />,
            resizable: false,
            reorderable: false
        },
    ]

    return (
        <>
            <div className="example-wrapper">
                <TileLayout
                    columns={12}
                    rowHeight={'auto'}
                    gap={{
                        rows: 10,
                        columns: 10,
                    }}
                    items={tiles}
                />
            </div>

        </>
    )
}