import React from "react";

const ColumnValor = ({ item, index, editMode, editingData, setEditingData }) => {
    const getTotalPayment = (obj) => {
        if (obj.dynamicInputsData) {
            return obj.dynamicInputsData.tablero.totalPayment;
        } else {
            return obj.Data.totalPayment;
        }
    };

    const totalPayment = getTotalPayment(item);
    const editPayment = editMode[index] ? editingData[index].dynamicInputsData?.tablero.totalPayment : totalPayment;

    const handleInputChange = (event) => {
        const newValue = event.target.value;

        setEditingData((prevState) => ({
            ...prevState,
            [index]: {
                ...prevState[index],
                dynamicInputsData: {
                    ...prevState[index].dynamicInputsData,
                    tablero: {
                        //...prevState[index].dynamicInputsData.tablero,
                        totalPayment: newValue
                    }
                }
            }
        }));
    };

    return (
        <td className="renewalsAgentPanel-td">
            {editMode[index] ? (
                <input
                    className='renewalsAgentPanelInput'
                    value={editPayment}
                    onChange={handleInputChange}
                />
            ) : (
                totalPayment
            )}
        </td>
    );
};

export { ColumnValor };
