import {Field} from '@progress/kendo-react-form';
import {ImpactTypes, incidenceTypes} from '../../components/RequestForm/data.jsx';
import { useState } from 'react';
import useAxios from '../../hooks/UseAxios';
import { Loader } from '@progress/kendo-react-indicators';
import { FormInput, FormRadioGroup,
	FormTextArea,
	FormDropDownList,} from '../../components/RequestForm/form-components.js';
import { requiredValidator } from '../../components/RequestForm/Validators.js';
import { baseURL } from '../../../Config.js';
export const Step2Incidencia = ()=>{
	
	const [chossedSubArea, setChossedSubArea] = useState([])
	const{data, isLoading, error}=useAxios(`${baseURL}/api/v1/helpDesk/AreaAndSubAreas`)
	const handleSubArea=(event) => {
	    setChossedSubArea(data[event.value])
	}
	return(
		<div>
		<Field
			key={'requestDate'}
			id={'requestDate'}
			name={'requestDate'}
			label={'Fecha de registro:'}
			component={FormInput}
			disabled={true}
		/>
		<Field
			key={'requestNeed'}
			id={'requestNeed'}
			name={'requestNeed'}
			label={'Tipo de incidencia'}
			layout={'horizontal'}			
			component={FormRadioGroup}
			data={incidenceTypes}
			validator={requiredValidator}
		/>
		<Field
			key={'requestProduct'}
			id={'requestProduct'}
			name={'requestProduct'}
			label={'¿Qué tipo de problema tiene?'}
			placeholder={"Describa la incidencia"}
			hint={'Escriba una breve descripción'}
			component={FormInput}
			validator={requiredValidator}
		/>
		<Field
			key={'requestItem'}
			id={'requestItem'}
			name={'requestItem'}
			label={'¿Cuál es el item?'}
			placeholder={"Nombre del Item ejemplo: ACEntry, AspelDataExtraction"}
			hint={'Nombre del Item'}
			component={FormInput}
			validator={requiredValidator}
		/>
		<Field
			key={'requestTool'}
			id={'requestTool'}
			name={'requestTool'}
			label={'¿Cuál es la herramienta?'}
			placeholder={"Nombre de la herramienta ejemplo: SQL query, Data Factory, etc"}
			hint={'Nombre de la herramienta'}
			component={FormInput}
			validator={requiredValidator}
		/>
		<Field
			key={'descriptionTask'}
			id={'descriptionTask'}
			name={'descriptionTask'}
			label={'¿Qué sucede con el item?'}
			placeholder={"Ingresa una descripción del problema"}
			component={FormTextArea}
			validator={requiredValidator}
		/>
		<Field
			key={'requestImpact'}
			id={'requestImpact'}
			name={'requestImpact'}
			label={'¿Cúal es el impacto en la operación?'}
			layout={'horizontal'}			
			component={FormRadioGroup}
			data={ImpactTypes}
			validator={requiredValidator}
		/>
		<Field
			key={'areas'}
			id={'areas'}
			name={'areas'}
			label={'¿A qué área corresponden los datos?'}
			component={FormDropDownList}
			data={Object.keys(data)}
			onChange={handleSubArea}
			validator={requiredValidator}
		/>
		{isLoading && <Loader size="medium" />}
		<Field
			key={'subarea'}
			id={'subarea'}
			name={'subarea'}
			label={'¿Cuál es la subarea?'}
			component={FormDropDownList}
			data={chossedSubArea}
			validator={requiredValidator}
		/>
		{error && <h3>{error}</h3>}
		
	</div>
	);
}
	

