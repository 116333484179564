import React from 'react';

export const PricingJobs = () => {
	const srcReportDatabricks = process.env.REACT_APP_IFRAME_DATABRICKS_REPORT;
	return (
		<div>
			<html>
				<body>
					<iframe
						src={srcReportDatabricks}
						width="100%"
						frameborder="0"></iframe>
				</body>
			</html>
		</div>
	);
};
