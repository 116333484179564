import React, { useState, useEffect } from 'react';
import { Loading } from './Loading/Loading'
import { Profile } from './Profile/Profile'
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Grid } from '@mui/material';
import UploadCenter from './UploadCenter';
import SkillToCellEditor from './SkillToCellEditor';
import ForecastVsReal from './ForecastVsReal';
import { ShiftNovelties } from '../ShiftNovelties/ShiftNovelties'
import { RealTimeStatesTable } from './RealTimeStatesTable/RealTimeStatesTable';
import { WhatsAppConnect } from './WhatsAppConnect/WhatsAppConnect';
import { MassiveIncidents } from './MassiveIncidents/MassiveIncidents';
import { Recall } from './Recall/Recall';
import './Siignal.css'

const SiignalDashboard = () => {
    const [loading, setLoading] = useState(true);
    const [panelSelected, setPanelSelected] = useState(0);

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <>
            {
                loading ? <Loading /> : <>
                    <Grid className="paddingTop">
                        <TabStrip selected={panelSelected} onSelect={(ev) => { setPanelSelected(ev.selected) }}>
                            <TabStripTab title="Ejecucion vs Dimensionamiento">
                                <ForecastVsReal />
                            </TabStripTab>
                            <TabStripTab title="Personal">
                                <Profile />
                            </TabStripTab>
                            <TabStripTab title="Estados">
                                <RealTimeStatesTable />
                            </TabStripTab>
                            <TabStripTab title="Horarios Y Novedades">
                                <ShiftNovelties />
                            </TabStripTab>
                            <TabStripTab title="Skills Editor">
                                <SkillToCellEditor />
                            </TabStripTab>
                            <TabStripTab title="WhatsApp Connect">
                                <WhatsAppConnect />
                            </TabStripTab>
                            <TabStripTab title="Incidentes Masivos">
                                <MassiveIncidents />
                            </TabStripTab>
                            <TabStripTab title="Llamada Agendada">
                                <Recall />
                            </TabStripTab>
                            <TabStripTab title="Centro de Carga">
                                <UploadCenter />
                            </TabStripTab>

                        </TabStrip>
                    </Grid>
                </>
            }
        </>
    )

}


export { SiignalDashboard };