import {Grid, GridColumn, GridNoRecords} from '@progress/kendo-react-grid';
import React from 'react';

import {formatDate} from '@telerik/kendo-intl';
import { useSelector } from 'react-redux';
import { selecthistory } from '../../../../redux/ControlCenterReducer';
import { DetailsRow } from '../DetailsRow';

export const UpdatesTable = ({expandChange, datasetId}) => {

	const DetailsComponent = (props) => {
		return <DetailsRow {...props} datasetId={datasetId} />;
	};
    const datarefresh=useSelector(selecthistory);

	const adjustTimeZone = (dateString) => {
		const date = new Date(dateString);
		// Restar 5 horas UTC
		date.setHours(date.getHours());
		return date;
	};

	const CustomCell = (props) => {
		let value = props.dataItem[props.field];
		if (props.field === 'startTime' || props.field === 'endTime') {
			value = adjustTimeZone(value);
		}
		return <td>{formatDate(value, 'yyyy-MM-dd HH:mm:ss')}</td>;
	};

	return (
		<Grid
			style={{
			  width: "100%",
			  height: "600px",
			}}
			detail={DetailsComponent}
			data={datarefresh}
			expandField="expanded"
			onExpandChange={expandChange}>
			<GridNoRecords>There is no data available</GridNoRecords>
			<GridColumn field="status" title="Estado" width={'100'} />
			<GridColumn
				field="startTime"
				width={'100'}
				title="Inicio Hora Col"
				format="{0:D}"
				cell={CustomCell}
			/>
			<GridColumn field="endTime" title="Fin Hora Col" cell={CustomCell} />
			<GridColumn field="refreshType" title="Tipo" width={'100'} />
			<GridColumn field="requestId" title="ID" />
		</Grid>
	);
};
