import React, { useEffect, useRef, useState } from "react";
import { Popup } from "@progress/kendo-react-popup";
import { xIcon, sparklesIcon } from "@progress/kendo-svg-icons";
import { FloatingActionButton } from "@progress/kendo-react-buttons";
import ChatBox from "../Chatbot/components/ChatBox";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { baseURL } from "../Config";

const Chatmicrosoft = ({ isAllowedChatbotAlice }) => {
  const { instance, accounts } = useMsal();
  const email = accounts[0].username;
  const [iframeSrc, setIframeSrc] = useState("");
  const [showAIPrompt, setShowAIPrompt] = useState(false);
  const anchorRef = useRef(null);

  const toggleAIPrompt = () => setShowAIPrompt(!showAIPrompt);

  useEffect(() => {
    const fetchIframeSrc = async () => {
      try {
        const response = await axios.post(`${baseURL}/api/v1/Users/iframe-src`, {
          email,
        });
        setIframeSrc(response.data.src);
      } catch (error) {
        console.error("Error fetching iframe src:", error);
      }
    };
	if(email){
     	fetchIframeSrc();}
  }, [email]);

  return (
    <>
      <FloatingActionButton
        style={{ zIndex: 100 }}
        ref={anchorRef}
        svgIcon={showAIPrompt ? xIcon : sparklesIcon}
        align={{ horizontal: "end", vertical: "bottom" }}
        alignOffset={{ x: 40, y: 40 }}
        positionMode="absolute"
        onClick={toggleAIPrompt}
      />
      <Popup
        anchor={anchorRef.current?.element}
        show={showAIPrompt}
        popupAlign={{ vertical: "bottom", horizontal: "right" }}
        anchorAlign={{ vertical: "bottom", horizontal: "left" }}
      >
        {!isAllowedChatbotAlice ? (
          <iframe
            src={iframeSrc}
            width="500px"
            height="700px"
            frameBorder="0"
          ></iframe>
        ) : (
           <ChatBox />
        )}
      </Popup>
    </>
  );
};

export default Chatmicrosoft;
