import {useEffect, useState} from 'react';
import {Button} from '@progress/kendo-react-buttons';
import {arrowRotateCwIcon, xIcon} from '@progress/kendo-svg-icons';
import {Push} from '@progress/kendo-react-animation';
import axios from 'axios';
import { baseURL } from '../../../Config';

export const ButtonRefresh = ({dataLastRow={datasetId: '', requestId: '', type: ''}}) => {

    const { type, datasetId, requestId } = dataLastRow;
	const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
	const [MessageAfterRefresh, setMessageAfterRefresh] = useState(null);
	const [show, setShow] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false)
    const [typeButton, setTypeButton] = useState(null)

    useEffect(() => {
        let detailsButton={
            Failed:{
                text: ' Reintentar Actualización del Reporte',
                icon: arrowRotateCwIcon,
                className: 'buttons-container-button buttonRefresh'
            },
            Completed:{
                text: '',
                icon: '',
                className: 'buttons-container-button buttonRefresh'
            },
            Unknown:{
                text: 'Detener Actualización',
                icon: xIcon,
                className: 'buttons-container-button buttonRefresh red'
            },
            Cancelled:{
                text: 'Reintentar Actualización del Reporte',
                icon: arrowRotateCwIcon,
                className: 'buttons-container-button buttonRefresh'
            }
        }
      //setTypeButton(detailsButton['Failed'])
      setTypeButton(detailsButton[type])  //Prueba
    }, [type])
    

	const handleClickRefresh = () => {
		
		setShow(false);
		if (type === 'Failed'||type === 'Cancelled') {
            setIsLoadingRefresh(true);
			axios
				.post(
					`${baseURL}/api/v1/pbi/Reports/Refresh/Update?datasetId=${datasetId}`
				)
				.then((response) => {
					console.log(response);
					setMessageAfterRefresh(
						'Se ha mandado correctamente la solicitud de actualización'
					);
				})
				.catch((error) => {
					console.log(error);
					setMessageAfterRefresh('No se ha podido completar la solicitud');
				})
				.finally(() => {
					setDisabledButton(true);
				});
		} else {
            console.log('sabecositas')
			axios
				.delete(
					`${baseURL}/api/v1/pbi/Reports/Refresh/Update?datasetId=${datasetId}&refreshId=${requestId}`
				)
				.then((response) => {
					console.log(response);
					setMessageAfterRefresh(
						'Se ha detenido correctamente la actualización'
					);
				})
				.catch((error) => {
					console.log(error);
					setMessageAfterRefresh('No se ha podido completar la solicitud');
				}).finally(() => {  
                    setDisabledButton(true);
                });
		}
		setTimeout(() => {
			setIsLoadingRefresh(false);
			setShow(true);
		}, 3000);
	};

	const children = show ? (
		<div style={{display: 'flex', flexDirection: 'column'}}>
			<h5 style={{width: '590px'}}>{MessageAfterRefresh}</h5>
			<span style={{color: 'gray', fontSize: '12px', marginTop: '-11px'}}>
				vuelva a revisar en unos minutos
			</span>
		</div>
	) : null;
	return (
		<div style={{display: 'flex'}} className="">
			{
                type!=='Completed'&&type!==''?(<Button
                    fillMode={'link'}
                    themeColor={'null'}
                    disabled={disabledButton}
                    onClick={handleClickRefresh}
                    style={{marginLeft: '10px', marginRight: '10px'}}
                    className={(type==='Failed'||type==='Cancelled')&&isLoadingRefresh?`buttonGirador ${typeButton?.className}`:typeButton?.className}
                    svgIcon={typeButton?.icon}>{typeButton?.text}</Button>):(null)
            }
			<Push transitionEnterDuration={1000} transitionExitDuration={1000}>
				{children}
			</Push>
		</div>
	);
};
