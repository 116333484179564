import React, {useState, useEffect} from 'react';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { FieldProps } from '@progress/kendo-react-form';
import {tipoTrabajo, pais, area } from './data';
import axios from 'axios'
import { baseURL } from '../../Config';


export const TherapistEditor =  (props: FieldProps) => {
  const [resultsActivities, setResultsActivities] = useState([]);
  const email = sessionStorage.getItem('usr')
  useEffect(() => {
    const getHDActivities = async () => {
        try {
            const responseActivities = await axios.get(`${baseURL}/api/v1/TReporting/TRAcitiviesHD?id=${email}`);
            setResultsActivities(responseActivities.data);
        } catch (error) {
            setResultsActivities([]);
        }
    };
    getHDActivities();
}, []);


    const handleChange = (event: DropDownListChangeEvent) => {
      if (props.onChange) {
        props.onChange.call(undefined, {
          value: event.value.ticketId
        });
      }
    };
    return (
      <DropDownList
      onChange={handleChange}
      value={resultsActivities.find((p: { ticketId: any; }) => p.ticketId === props.value)}
      data={resultsActivities}
      dataItemKey={'ticketId'}
      textField={'ticketId'}
      />
    );
};

export const RoomEditor = (props: FieldProps) => {
  const handleChange = (event: DropDownListChangeEvent) => {
    if (props.onChange) {
      props.onChange.call(undefined, { value: event.value.id });
    }
  };

  return (
    <DropDownList
      onChange={handleChange}
      value={tipoTrabajo.find((r) => r.id === props.value)}
      data={tipoTrabajo}
      dataItemKey={'id'}
      textField={'title'}
    />
  );
};

export const CountryEditor = (props: FieldProps) => {
  const handleChange = (event: DropDownListChangeEvent) => {
    if (props.onChange) {
      props.onChange.call(undefined, { value: event.value.id });
    }
  };

  return (
    <DropDownList
      onChange={handleChange}
      value={pais.find((p) => p.id === props.value)}
      data={pais}
      dataItemKey={'id'}
      textField={'text'}
    />
  );
};

export const AreaEditor = (props: FieldProps) => {
  const handleChange = (event: DropDownListChangeEvent) => {
    if (props.onChange) {
      props.onChange.call(undefined, { value: event.value.id });
    }
  };

  return (
    <DropDownList
      onChange={handleChange}
      value={area.find((a) => a.id === props.value)}
      data={area}
      dataItemKey={'id'}
      textField={'text'}
    />
  );
};