import axios from "axios";
import { baseURL } from "../../../../Config";

export const handleDownload = (uid, setToast, folder,FileName='nombre_del_archivo.ext',extension,setIsDownload) => {
    setIsDownload(true)
    axios
        .get(`${baseURL}/api/v1/FileManagement/files?id=${uid}${extension}&folder=${folder}`, { responseType: 'blob' }) // Asegúrate de usar 'blob' como responseType
        .then((response) => {
            // Crear un URL temporal para el archivo
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            console.log(response)

            // Puede ser necesario especificar el nombre del archivo
            link.setAttribute('download', FileName);
            // Puede ser necesario especificar el nombre del archivo


            // Añadir el link al documento y hacer clic en él
            document.body.appendChild(link);
            link.click();

            // Limpiar y remover el link
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);

            setToast({ snackbarOpen: true, message: 'Archivo descargado con éxito', type: 'info' });
            setIsDownload(false)
        })
        .catch((error) => {
            console.error('Error al descargar el archivo:', error);
            setToast({ snackbarOpen: true, message: 'Error al descargar el archivo', type: 'error' });
            setIsDownload(false)
        });
        
}