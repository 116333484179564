import axios from 'axios';
import {baseURL} from '../../Config';

export const reportsDB = async (searchRute, area, rol, name) => {
	let setError = '';

	// Determina la URL base dependiendo del entorno

	// Codificar los componentes de la URL
	let encodedSearchRute = encodeURIComponent(searchRute);
	const encodedRol = encodeURIComponent(rol);
	const encodedArea = encodeURIComponent(area);
	const encodedName = encodeURIComponent(name);
	encodedSearchRute = encodedSearchRute.replace(/\*/g, '%2A');

	try {
		const res = await axios
			.get(
				`${baseURL}/api/v1/pbi/RenderReports?rute=${encodedSearchRute}&rol=${encodedRol}&area=${encodedArea}&user=${encodedName}`,
				{
					headers: {
						'Content-Type': 'application/json; charset=UTF-8',
						'Access-Control-Allow-Origin': '*',
					},
				}
			)
			.catch(function (error) {
				if (error.response) {
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
					setError = error.response.data.detail.errorMsg;
				} else if (error.request) {
					console.log(error.request);
					setError = 'Oops, something went wrong.';
				} else {
					console.log('Error', error.message);
					setError = 'Oops, something went wrong.';
				}

				console.log(error.config);
			});
		return res.data;
	} catch (e) {
		console.error(e);
		return setError;
	}
};
