import React, {useEffect, useState} from 'react';
import {
	ToggleButtonGroup,
	ToggleButton,
	FormControl,
	InputLabel,
	Select,
	Paper,
	Chip,
	Button,
	Stack,
	Box,
	TextField,
	MenuItem,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import useAxiosPut from '../../../../../../HelpDesk/hooks/UseAxiosPut';
import { baseURL } from '../../../../../../Config';
import { useDispatch } from 'react-redux';
import { setSubMenu } from '../../../../../../redux/ControlCenterReducer';

export default function DataSourceSelector({
	setToast,
	email,
	UrlReport,
	data,
	isLoading,
	dataFromReport,
	loadingDataFromReport,
}) {
	const [dataSource, setDataSource] = useState('databricks');
	const [database, setDatabase] = useState('');
	const [table, setTable] = useState([]); // Ahora es un array para selección múltiple
	const [filePath, setFilePath] = useState('');
	const [fileName, setFileName] = useState('');
	const [selectedItems, setSelectedItems] = useState([]);
	const [dataSelector, setDataSelector] = useState([]);
	const [tableSelector, setTableSelector] = useState([]);
	const [isPutExecuted, setIsPutExecuted] = useState(false);
	const dispatch=useDispatch()
	const{responseData, statusResponse, isLoading:loadingPut, error, executePut}=useAxiosPut(`${baseURL}/api/v1/pbi/Reports/metadataUpdate`)
	const handleDataSourceChange = (event, newDataSource) => {
		if (newDataSource !== null) {
			setDataSource(newDataSource);
			setDatabase('');
			setTable([]);
			setFilePath('');
			setFileName('');
		}
	};

	const handleDatabaseChange = (event) => {
		setDatabase(event.target.value);
		setTableSelector(
			dataSelector.find((objeto) => objeto.baseDatos === event.target.value)
				?.tables
		);
	};

	const handleTableChange = (event) => {
		const {target: {value}} = event;
		setTable(
			typeof value === 'string' ? value.split(',') : value
		);
	};

	useEffect(() => {
		if (dataFromReport.lineage) {
		  dataFromReport.lineage.internal_source?.forEach((element) => {
			element.tables.forEach((table) => {
			  setSelectedItems((prevItems) => [
				...prevItems,
				`${element.schema_name}: tabla ${table.table_name}`
			  ]);
			});
		  });
		  dataFromReport.lineage.external_source?.forEach((element) => {
			element.tables.forEach((file) => {
			  setSelectedItems((prevItems) => [
				...prevItems,
				`${element.file_location} nombre: ${file.file_name}`
			  ]);
			});
		  });
		}
	  }, [dataFromReport]);

	const handleAddItem = () => {
		if (dataSource === 'databricks' && database && table.length) {
			table.forEach((table) => {
			setSelectedItems((prevItems) => [
				...prevItems,
				`${database}: tabla ${table}`
			]);
			});
			setDatabase('');
			setTable([]);
		} else if (dataSource === 'external' && filePath && fileName) {
			setSelectedItems([...selectedItems, `${filePath} nombre: ${fileName}`]);
			setFilePath('');
			setFileName('');
		}
	};

	const handleDeleteItem = (itemToDelete) => {
		setSelectedItems(selectedItems.filter((item) => item !== itemToDelete));
	};

	const handleSave = async () => {
		const internal_source = [];
		const external_source = [];
		selectedItems.forEach((item) => {
			if (item.includes(': tabla')) {
				const [schema_name, table_name] = item.split(': tabla ');
				const index = internal_source.findIndex(
					(element) => element.schema_name === schema_name
				);
				if (index === -1) {
					internal_source.push({
						schema_name,
						tables: [{table_name}],
					});
				} else {
					internal_source[index].tables.push({table_name});
				}
			} else {
				const [file_location, file_name] = item.split(' nombre: ');
				const index = external_source.findIndex(
					(element) => element.file_location === file_location
				);
				if (index === -1) {
					external_source.push({
						file_location,
						tables: [{file_name}],
					});
				} else {
					external_source[index].tables.push({file_name});
				}
			}
		});
		const data = {
			internal_source,
			external_source,
		};
		await executePut({
			route: UrlReport,
			filter: 'lineage',
			fields: {lineage: data},
			user: email,
		});
		setIsPutExecuted(true);	

	}
	useEffect(() => {
		if (isPutExecuted) {
			if (statusResponse === 200) {
				setToast({
					type: 'success',
					message: 'Cambios guardados correctamente',
					snackbarOpen: true,
				});
				console.log('Todo melo ', responseData);
			} else {
				setToast({
					type: 'error',
					message: 'Error al guardar los cambios',
					snackbarOpen: true,
				});
				console.log(error);
			}
	
			dispatch(setSubMenu('Updates'));
	
			// Resetear el estado para evitar que se ejecute varias veces
			setIsPutExecuted(false);
		}
	}, [statusResponse, isPutExecuted]); // Añadir isPutExecuted como dependencia
	


	useEffect(() => {
		setDataSelector(
			data?.map((objeto) => {
				let tables = [];
				if (Array.isArray(objeto.tabla)) {
					tables = objeto.tabla.map((tabla) => tabla.baseDatos);
				}
				return {
					baseDatos: objeto.baseDatos,
					tables: tables,
				};
			})
		);
	}, [data]);

	return (
		<Stack spacing={2} sx={{width: 770, padding: 1}}>
			<ToggleButtonGroup
				value={dataSource}
				exclusive
				onChange={handleDataSourceChange}
				aria-label="data source">
				<ToggleButton value="databricks" aria-label="databricks">
					Databricks
				</ToggleButton>
				<ToggleButton value="external" aria-label="ubicación externa">
					Ubicación externa
				</ToggleButton>
			</ToggleButtonGroup>

			{dataSource === 'databricks' ? (
				<Stack direction="row" spacing={2}>
					<FormControl fullWidth>
						<InputLabel id="database-label">Base de datos</InputLabel>
						<Select
							labelId="database-label"
							value={database}
							label="Base de datos"
							onChange={handleDatabaseChange}>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{dataSelector?.map((objeto, index) => (
								<MenuItem
									key={`${objeto.baseDatos}-${index}`}
									value={objeto.baseDatos}>
									{objeto.baseDatos}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<FormControl fullWidth>
						<InputLabel id="table-label">Tablas</InputLabel>
						<Select
							labelId="table-label"
							multiple
							value={table}
							onChange={handleTableChange}
							renderValue={(selected) => (
								<Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
									{selected.map((value) => (
										<Chip key={value} label={value} />
									))}
								</Box>
							)}>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{tableSelector?.map((table, index) => (
								<MenuItem key={index} value={table}>
									{table}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Stack>
			) : (
				<Stack direction="row" spacing={2}>
					<TextField
						fullWidth
						label="Ruta"
						value={filePath}
						onChange={(e) => setFilePath(e.target.value)}
						placeholder="Ingrese la ruta"
					/>
					<TextField
						fullWidth
						label="Nombre archivo"
						value={fileName}
						onChange={(e) => setFileName(e.target.value)}
						placeholder="Ingrese el nombre del archivo"
					/>
				</Stack>
			)}

			<Button variant="contained" onClick={handleAddItem}>
				Agregar
			</Button>

			<Paper
				elevation={3}
				sx={{
					p: 2,
					minHeight: 100,
					maxHeight: 200,
					overflowY: 'auto',
				}}>
				<Stack spacing={1}>
					{selectedItems.map((item, index) => (
						<Chip
							key={index}
							label={item}
							onDelete={() => handleDeleteItem(item)}
							deleteIcon={<DeleteIcon />}
						/>
					))}
				</Stack>
			</Paper>

			<Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
				<Button variant="contained" color="primary" onClick={handleSave} disabled={isLoading||loadingPut}>
					Guardar cambios
				</Button>
			</Box>
		</Stack>
	);
}

// {
// 	"ticketId": 7178,
// 	"commentOwner": {
// 	  "name": "Hosta",
// 	  "mail": "ca@sio.com"
// 	},
// 	"timeElapsedStatus": 57.7,
// 	"date": "2024,10,16,16,17,34,983390",
// 	"comment": "<p><span style="color:#55aa40;">Status actualizado correctamente a Proceso</span></p>",
// 	"isSystem": True
//   }
