import { createSlice } from '@reduxjs/toolkit';


export const ControlCenterSlice = createSlice({
    name: 'ControlCenter',
    initialState: {
        history:null,
        lastUpdate:null,
        Menuwidth:720,
        isLoadingControlCenter:false,
        isOpenSeconDisplay:false,
        subMenu:'KPIs'
    },
    reducers: {

        setHistory:(state,action)=>{
            state.history=action.payload;
        },
        changeIsLoadingControlCenter:(state,action)=>{
            state.isLoadingControlCenter=action.payload
        },
        setLastUpdate:(state,action)=>{
            state.lastUpdate=action.payload
        },
        setMenuWidth:(state,action)=>{
            state.Menuwidth=action.payload
        },
        setOpenSeconDisplay:(state,action)=>{
            state.isOpenSeconDisplay=action.payload
        },
        setSubMenu:(state,action)=>{
            state.subMenu=action.payload
        }

    }
    
});
export const thunkGetMonthOperation=()=>{
    return async (dispatch,getState)=>{
        
        dispatch(changeIsLoadingControlCenter(false));
    }
}
export const { setHistory,changeIsLoadingControlCenter,setLastUpdate,setMenuWidth,setOpenSeconDisplay,setSubMenu } = ControlCenterSlice.actions; 

export const selecthistory = state => state.ControlCenter.history;
export const selectlastUpdate = state => state.ControlCenter.lastUpdate;
export const selectMenuWidth = state => state.ControlCenter.Menuwidth;
export const selectisOpenSeconDisplay = state => state.ControlCenter.isOpenSeconDisplay;
export const selectSubMenu = state => state.ControlCenter.subMenu;

export const selectisLoadingControlCenter = state => state.ControlCenter.isLoadingControlCenter;
export default ControlCenterSlice.reducer