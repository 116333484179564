import React, {useEffect, useState} from 'react';
import PermissionAssignment from './DragAndDrop';
import useAxios from '../../../../hooks/useAxios';
import {baseURL} from '../../../../../Config';
import useAxiosPut from '../../../../../HelpDesk/hooks/UseAxiosPut';
import {useSelector} from 'react-redux';
import {selectUrl} from '../../../../../redux/viewBISlice';
import useAxiosPost from '../../../../../components/ViewCustomer/Hook/useAxiosPost';
import {selectCredencialesUsr} from '../../../../../redux/credencialesReducer';
import { Box, Skeleton, Typography } from '@mui/material';

export const GrantPermissions = ({setToast}) => {
	const UrlReport = useSelector(selectUrl);
	const email = useSelector(selectCredencialesUsr);
	const{data:tickets,isLoading:loadingTickets}=useAxios(`${baseURL}/api/v1/TReporting/TRAcitiviesHD?id=${email}`);
	const {
		data: permissions,
		loading,
		error: erroPermissions,
		postData,
	} = useAxiosPost(`${baseURL}/api/v1/pbi/Reports/metadata`);
	
	const [listPermissions, setListPermissions] = useState([]);
	const {
		data: areas,
		isLoading: isLoadingAreas,
		error: errorAreas,
	} = useAxios(`${baseURL}/api/v1/helpDesk/AreasEmployees`);
	const {
		responseData: cargos,
		statusResponse,
		isLoading,
		error,
		executePut,
	} = useAxiosPut(`${baseURL}/api/v1/helpDesk/ListOfCargos`);
	const [finalDataAreas, setFinalDataAreas] = useState([]);
	const [listCargos, setListCargos] = useState([])
	const [listAreas, setListAreas] = useState([])
	useEffect(() => {
		if (permissions && typeof permissions === 'object' && Object.keys(permissions).length > 0) {
			setListPermissions(permissions);
			if (Array.isArray(areas)) {
				const NotcommonElements = areas.filter(element => !permissions.Area.includes(element));
				setListAreas(NotcommonElements);
			}
		}
	}, [permissions,areas]);
	useEffect(() => {
		postData({
			email: email,
			GetAtribute: {
				filter: {route: UrlReport},
				fields: ['user', 'Area']},
		});
	}, [])
	useEffect(() => {
	  if (Array.isArray(cargos)) {
		let listfirstCargos=cargos.reduce((acc,item)=>{
			acc[item]=false
			return acc;
	  },{})

			let CommonCargos=cargos.forEach(element => {
				if (permissions.user.includes(element)) {
					listfirstCargos[element]=true
				}
			});
	    setListCargos(listfirstCargos)
	  }

	}, [cargos])
	
	

	if (isLoadingAreas ||loading||loadingTickets) {
		return (<Box sx={{width: 800, margin: '0 auto', padding: 2}}>
			<Typography variant="h1"><Skeleton /></Typography>
			<Typography variant="h1"><Skeleton /></Typography>
			<Typography variant="h1"><Skeleton /></Typography>
			</Box>
		);
	}

	return (
		<div>
			 <PermissionAssignment setToast={setToast}tickets={tickets} email={email} UrlReport={UrlReport} isLoading={isLoading}CollapseData={listPermissions?.Area} listAreas={listAreas}listCargos={listCargos}setListAreas={setListAreas} listPermissions={listPermissions} setListPermissions={setListPermissions} executePut={executePut}/>
		</div>
	);
};
export default GrantPermissions;
