import React from 'react';
import './ExecutionTimesBar.css';

const ExecutionTimesBar = ({min, avg, max}) => {
	// Calcula las posiciones en la barra
	const avgPosition = ((avg - min) / (max - min)) * 100;

	// Ajusta las posiciones para que no se salgan del contenedor
	const getPositionStyle = (position) => {
		if (position < 10) return {left: '0%', transform: 'translateX(0)'};
		if (position > 90) return {left: '100%', transform: 'translateX(-100%)'};
		return {left: `${position}%`, transform: 'translateX(-50%)'};
	};
	const CustomCell = (props) => {
		if (props.number === undefined) {
		  return <>0</>;
		}
		if (props.number > 60) {
		  const minutes = Math.floor(props.number / 60);
		  const seconds = Math.round(props.number % 60); 
		  return <>{`${minutes}:${seconds.toString().padStart(2, '0')} min`}</>;
		}
		return <>{props.number}s</>;
	  }


	return (
		<div className="bar-container">
			<div className="bar">
				<div className="marker min" style={{left: '0%'}}>
				<CustomCell number={min}/>
				</div>
				<div className="marker avg" style={{left: `${avgPosition}%`}}>
					<CustomCell number={avg}/>
				</div>
				<div className="marker max" style={{left: '100%'}}>
					<CustomCell number={max}/>
				</div>
			</div>
			<div className="bar-labels">
				<div className="bar-label" style={getPositionStyle(0)}>
					<div>min</div>
				</div>
				<div className="bar-label" style={getPositionStyle(avgPosition)}>
					avg
				</div>
				<div className="bar-label" style={getPositionStyle(100)}>
					<div>max</div>
				</div>
			</div>
		</div>
	);
};

export default ExecutionTimesBar;
