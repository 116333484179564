import React, { useState } from 'react';
import './Recall.css'
import axios from 'axios';

const Recall = () => {
  const [firstName, setFirstName] = useState('');
  const [company, setCompany] = useState('Siigo');
  const [number1, setNumber1] = useState('');
  const [SeguimientoOUT, setSeguimientoOUT] = useState('');
  const [F9list, setSkill] = useState('Col_Ventas_Perfilamiento_LlamadaAgendada');
  const [country, setCountry] = useState('CO');
  const [horaLlamadaAgendada, setHoraLlamadaAgendada] = useState('');
  const [result, setResult] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    // Convierte `horaLlamadaAgendada` a `yyyy-MM-dd HH:mm:ss`
    const [date, time] = horaLlamadaAgendada.split('T');
    const formattedDateTime = `${date} ${time}:00`; // Añadiendo los segundos

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer 0");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow"
    };

    const url = `https://api.five9.com/web2campaign/AddToList?F9domain=Siigo S.A.&F9list=${F9list}&F9CountryCode=${country}&number1=${number1}&F9key=first_name&F9key=company&F9TimeToCall=${horaLlamadaAgendada} -0500&F9TimeFormat=yyyy-MM-dd HH:mm:ss Z&first_name=${firstName}&company=${company}&Hora_Llamada_Agendada=${horaLlamadaAgendada}&Numero de Intento=1&F9Key=Numero de Intento`;
    
    fetch(url, requestOptions)
      .then((data) => {
        setResult(data); // Set the result data
        setErrorMessage(''); // Clear any previous error message
        let info={
            'Creator':firstName,
            'Hora_programada':horaLlamadaAgendada,
            'Numero_de_seguimiento': SeguimientoOUT,
        }
        saveRecall(info)
        alert('La solicitd fue procesada con exito, bajo el nombre de '+firstName+' para la hora y fecha '+horaLlamadaAgendada+' Con el número de seguimiento '+SeguimientoOUT)
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage('An error occurred while submitting the form.');
        setResult(null); // Clear result on error
      });
  };
  const saveRecall = async(data) => {
    await axios.post("/api/Recall", data).then(res => {
        console.log(res)
    }).catch(err => {
        console.log(err)
        alert("Error al guardar el agendamiento...")
    })
    }

  return (
    <div>
      <h1>Formulario de llamadas re-agendadas</h1>
      <form className="scheduled-call-form" onSubmit={handleSubmit}>
        <div>
          <label>First Name: </label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div>
          <label>Company: </label>
          <input
            type="text"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
        </div>
        <div>
          <label>Number: </label>
          <input
            type="text"
            value={number1}
            onChange={(e) => setNumber1(e.target.value)}
          />
        </div>
        <div>
            <label>Hora Llamada Agendada: </label>
            <input
                type="datetime-local"
                value={horaLlamadaAgendada}
                onChange={(e) => setHoraLlamadaAgendada(e.target.value)}
            />
        </div>
        <div>
            <label>Skill Llamada Agendada: </label>
            <input
                type="text"
                value={F9list}
                onChange={(e) => setSkill(e.target.value)}
            />
        </div>
        <div>
            <label>Pais Llamada Agendada: </label>
            <input
                type="text"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
            />
        </div>
        <div>
          <label>Seguimiento OUT: </label>
          <input
            type="text"
            value={SeguimientoOUT}
            onChange={(e) => setSeguimientoOUT(e.target.value)}
          />
        </div>
        <button type="submit">Submit</button>
      </form>
      {errorMessage && <p>{errorMessage}</p>}
    </div>
  );
};

export {Recall};
