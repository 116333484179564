import {useEffect, useState} from 'react';
import {
	Checkbox,
	FormControlLabel,
	Button,
	Collapse,
	Box,
	Stack,
	Typography,
	Grid,
	Paper,
	MenuItem,
	Select,
	InputLabel,
	FormControl,
} from '@mui/material';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import useAxiosPut from '../../../../../HelpDesk/hooks/UseAxiosPut';
import {baseURL} from '../../../../../Config';
import {setSubMenu} from '../../../../../redux/ControlCenterReducer';
import {useDispatch} from 'react-redux';
import {GoNoEntry, GoFoldUp} from 'react-icons/go';
export default function PermissionAssignment({
	setToast,
	tickets = [],
	email,
	UrlReport,
	isLoading,
	CollapseData = [],
	listAreas,
	listCargos,
	setListAreas,
	listPermissions,
	setListPermissions,
	executePut,
}) {
	const dispatch = useDispatch();
	const {
		responseData,
		statusResponse,
		isLoading: isLoadingSaving,
		error,
		executePut: executePutSavingData,
	} = useAxiosPut(`${baseURL}/api/v1/pbi/Reports/metadataUpdate`);
	const [permissions, setPermissions] = useState({});
	const [openAreas, setOpenAreas] = useState({});
	const [openAddAreas, setOpenAddAreas] = useState(false);
	const [selectedTicket, setSelectedTicket] = useState('');
	const [isPutExecuted, setIsPutExecuted] = useState(false);
	const addArea = (newArea) => {
		const normalizedNewArea = newArea.trim().toLowerCase();
		const normalizedAreas = CollapseData.map((area) =>
			area.trim().toLowerCase()
		);

		if (!normalizedAreas.includes(normalizedNewArea)) {
			console.log(CollapseData);
			console.log([...CollapseData, newArea]);
			setListPermissions((prev) => ({
				...prev,
				Area: [...CollapseData, newArea],
			}));
		}

		setListAreas((prev) => {
			const newAreas = prev.filter((item) => item !== newArea);
			return newAreas;
		});
	};

	const handlePermissionChange = (area, position, checked) => {
		addArea(area);
		setPermissions((prev) => ({
			...prev,
			[area]: {
				...prev[area],
				[position]: checked,
			},
		}));
	};
	const toggleArea = (area) => {
		executePut({idArea: [area]});

		setOpenAreas((prev) => {
			const newOpenAreas = {...prev};
			Object.keys(newOpenAreas).forEach((key) => {
				newOpenAreas[key] = false;
			});
			newOpenAreas[area] = !prev[area];
			return newOpenAreas;
		});
	};
	const handleSelectChange = (event) => {
		setSelectedTicket(event.target.value);
	};

	const savePermissions = async () => {
		if (selectedTicket) {
			await executePutSavingData({
				route: UrlReport,
				filter: 'permissions',
				fields: permissions,
				user: email,
				ticket: selectedTicket,
			});
			setIsPutExecuted(true);

		}
	};

	useEffect(() => {
	  if(isPutExecuted){
		if (statusResponse === 200) {
			setToast({
				snackbarOpen: true,
				message: 'Permisos guardados correctamente',
				severity: 'success',
			});
		} else {
			console.log(error);
			setToast({
				snackbarOpen: true,
				message: 'Error al guardar los permisos',
				severity: 'error',
			});
		}
		dispatch(setSubMenu('Updates'));
	  }
	}, [isPutExecuted,statusResponse]);
	
	const deleteArea = (area) => {
		setListPermissions((prev) => {
			const newAreas = prev.Area.filter((item) => item !== area);
			return {...prev, Area: newAreas};
		});
		setPermissions((prev) => ({
			...prev,
			[area]: {
				...prev[area],
				DeleteArea: false,
			},
		}));
	};
	const toggleAddArea = () => {
		setOpenAddAreas(!openAddAreas);
	};

	return (
		<Box sx={{width: 760, margin: '0 auto', padding: 1}}>
			<Typography variant="h6" gutterBottom>
				Asignar Permisos:
			</Typography>
			{CollapseData.map((area) => (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<GoNoEntry
						className={'redhover'}
						onClick={() => deleteArea(area)}
						style={{
							width: '22px',
							height: '22px',
							marginRight: '15px',
							marginTop: '-15px',
						}}
					/>
					<Paper key={area} sx={{marginBottom: 2, padding: 1, width: '100%'}}>
						<Button
							fullWidth
							onClick={() => toggleArea(area)}
							endIcon={openAreas[area] ? <ExpandLess /> : <ExpandMore />}>
							{area}
						</Button>
						<Collapse in={openAreas[area]} timeout="auto" unmountOnExit>
							{isLoading ? (
								<h2>Cargando...</h2>
							) : (
								<Grid container spacing={2} sx={{padding: 2}}>
									{Object.entries(listCargos).map(
										([positionKey, positionValue]) => (
											<Grid item xs={12} sm={6} key={`${area}-${positionKey}`}>
												<FormControlLabel
													control={
														<Checkbox
															checked={
																permissions[area]?.[positionKey] ??
																positionValue
															}
															onChange={(e) =>
																handlePermissionChange(
																	area,
																	positionKey,
																	e.target.checked
																)
															}
															sx={{
																color: permissions[area]?.[positionKey]
																	? 'blue'
																	: positionValue
																	? 'gray'
																	: 'default',
																'&.Mui-checked': {
																	color: permissions[area]?.[positionKey]
																		? 'blue'
																		: positionValue
																		? 'gray'
																		: 'default',
																},
															}}
														/>
													}
													label={positionKey}
												/>
											</Grid>
										)
									)}
								</Grid>
							)}
						</Collapse>
					</Paper>
				</div>
			))}
			<Button
				fullWidth
				color="inherit"
				onClick={toggleAddArea}
				endIcon={openAddAreas ? <GoFoldUp /> : <AddCircleOutlineIcon />}>
				{openAddAreas ? 'Ocultar Areas Disponibles' : 'Agregar Area'}
			</Button>
			{openAddAreas &&
				listAreas.map((area) => (
					<Paper
						key={area}
						sx={{marginTop: 3, marginBottom: 2, padding: 1, width: '100%'}}>
						<Button
							fullWidth
							style={{color: 'gray'}}
							onClick={() => toggleArea(area)}
							endIcon={openAreas[area] ? <ExpandLess /> : <ExpandMore />}>
							{area}
						</Button>
						<Collapse in={openAreas[area]} timeout="auto" unmountOnExit>
							{isLoading ? (
								<h2>Cargando...</h2>
							) : (
								<Grid container spacing={2} sx={{padding: 2}}>
									{Object.entries(listCargos).map(
										([positionKey, positionValue]) => (
											<Grid item xs={12} sm={6} key={`${area}-${positionKey}`}>
												<FormControlLabel
													control={
														<Checkbox
															checked={
																permissions[area]?.[positionKey] ??
																positionValue
															}
															onChange={(e) =>
																handlePermissionChange(
																	area,
																	positionKey,
																	e.target.checked
																)
															}
															sx={{
																color: permissions[area]?.[positionKey]
																	? 'blue'
																	: positionValue
																	? 'gray'
																	: 'default',
																'&.Mui-checked': {
																	color: permissions[area]?.[positionKey]
																		? 'blue'
																		: positionValue
																		? 'gray'
																		: 'default',
																},
															}}
														/>
													}
													label={positionKey}
												/>
											</Grid>
										)
									)}
								</Grid>
							)}
						</Collapse>
					</Paper>
				))}

			<Stack direction="row" spacing={2} alignItems="center">
				<FormControl variant="outlined" sx={{minWidth: 120}} size="small">
					<InputLabel id="ticket-select-label">Ticket ID</InputLabel>
					<Select
						labelId="ticket-select-label"
						value={selectedTicket}
						onChange={handleSelectChange}
						label="Ticket ID">
						{tickets?.map((ticket) => (
							<MenuItem key={ticket.id} value={ticket.ticketId}>
								{ticket.ticketId}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<Button
					variant="contained"
					color="primary"
					onClick={savePermissions}
					disabled={isLoadingSaving||!selectedTicket}
					sx={{marginTop: 2}}>
					Guardar Permisos
				</Button>
			</Stack>
		</Box>
	);
}
