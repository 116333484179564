
import React, { useEffect, useState } from 'react';
import './style.css';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import CardGuest from './cards/cardsGuest';
import { SlArrowUp,SlArrowDown } from "react-icons/sl";
export const GuestHome = () => {
    const { instance, accounts } = useMsal();
    const name = accounts[0]?.name;
    const email = accounts[0]?.username;
    const navigate = useNavigate();
    const [onhover, setOnhover] = useState(false);
    const [showCards, setShowCards] = useState(false);

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        if (scrollPosition > 300) { // Ajusta la cantidad de scroll necesario
            setShowCards(true);
        } else {
            setShowCards(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    

    return (
        <div className="GuestHomeApp">
            <MainSection name={name} setOnhover={setOnhover} />
            <section className="Contact-section" style={{ paddingBottom: `${onhover ? '85px' : '45px'}`,height: `${showCards?'500px':''}` }}>
                <div className="contact-box" onMouseEnter={() => setOnhover(true)} onMouseLeave={() => setOnhover(false)}>
                    <div onClick={() => setShowCards(!showCards)} style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                    {showCards?<SlArrowDown style={{color:'white',width:'40px',height:'40px'}}/>:<SlArrowUp style={{color:'white',width:'40px',height:'40px'}}/>}
                    <p></p>
                    {showCards?'':<p>ACCEDER</p>}
                    </div>
                    
            {showCards&&<CardSection/>}
                </div>
            </section>
        </div>
    );
}

const MainSection = ({ name, setOnhover }) => (
    <section className="Main-section" >
        <div className="GuestHomeApp-content" onMouseEnter={() => setOnhover(true)} onMouseLeave={() => setOnhover(false)}>
            <h1>Hola, bienvenido/a {name}</h1>
            <h1><span style={{ fontSize: '115px' }}>Alianzas</span></h1>
        </div>
    </section>
);

const CardSection=()=>(
    <section className='Card-section-guest'>
        <div className='card-container-guest'>
        <CardGuest number={1} title={"Consumo Alianzas"} content={'Tendrás aquí las tablas que llenabas anteriormente en excel'} link={"/Template/Grid/Seguimientos Landing"}/>
        <CardGuest number={2} title={"Consumo Holcim"} content={'Reporte general'} link={'/Holcim/Reports'}/>
        </div>
    </section>
)

export default GuestHome;
