import React, { useState } from 'react';
import { searchByEmail, searchPeople } from '../../Graph';
import { useMsal } from '@azure/msal-react'; // Importa useMsal para obtener el token desde MSAL
import { loginRequest } from '../../Config';

export const UseSearchdirectory = () => {
    const [loading, setLoading] = useState(false);
    const [listNames, setListNames] = useState([]);
    
    const { instance, accounts } = useMsal(); // Obtén la instancia y las cuentas desde MSAL

    // Función para obtener el token de manera silenciosa
    async function getToken() {
        try {
            const tokenResponse = await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0] // Selecciona la cuenta actual
            });
            return tokenResponse.accessToken;
        } catch (error) {
            console.error('Error acquiring token silently:', error);
        }
    }

    // Función de búsqueda por nombre
    async function search(name) {
        if (name !== '') {
            setLoading(true);
            const token = await getToken();
            if (token) {
                searchPeople(token, name).then(response => {
                    setListNames(response.value?.map((objeto) => {
                        return {
                            email: objeto.userPrincipalName,
                            name: objeto.displayName
                        };
                    }));
                    setLoading(false);
                }).catch(error => {
                    console.error("No se puede conectar con Graph", error);
                    setLoading(false);
                });
            }
        }
    }

    // Función de búsqueda por correo electrónico
    async function searchByemail(email) {
        if (email !== '') {
            setLoading(true);
            const token = await getToken();
            if (token) {
                searchByEmail(token, email).then(response => {
                    setListNames(response.value?.map((objeto) => {
                        return {
                            email: objeto.userPrincipalName,
                            name: objeto.displayName
                        };
                    }));
                    setLoading(false);
                }).catch(error => {
                    console.error("No se puede conectar con Graph", error);
                    setLoading(false);
                });
            }
        }
    }

    return {
        search,
        loading,
        searchByemail,
        listNames
    };
}
