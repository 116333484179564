import * as React from 'react';
import { SchedulerForm } from '@progress/kendo-react-scheduler';
import { CustomFormEditor } from './custom-form-editor';
import { CustomDialog } from '../timeReportingV2/custom-dialog';
export const FormWithCustomEditor = props => {
  const isEditing = props.dataItem.ActivityID !== undefined;
  let initialValues= {
    Nombre: sessionStorage.getItem('name'),
    Usuario: sessionStorage.getItem('usr'),
    Start:props.dataItem.Start,
    End:props.dataItem.End
  }
  if(isEditing){
    initialValues= {...initialValues,
      ActivityID:props.dataItem.ActivityID,
      country:props.dataItem.Country,
      stakeholder: props.dataItem.Stakeholder,
      activityType:props.dataItem.ActivityType,
      ticketActivity:props.dataItem.idActivity,
      TituloActividad:props.dataItem.ActivityTitle,
      Descripcion:props.dataItem.Description
    }

    
  }
  const requiredValidator = React.useCallback(value => value === undefined || value === null || value === '' ? 'Campo Obligatorio' : undefined, []);
  const descriptionLengthValidator = React.useCallback(value => {
    return !value || value.length < 40 ? 'La Descripcion de la actividad tiene que ser de al menos 40 caracteres.' : undefined;
  }, []);
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const stakeholderValidator = React.useCallback(value =>{
  return !value ? "Stakeholder es requerido." : !emailRegex.test(value) ? "Stakeholder no es un correo válido." : "";
}, []);
  const formValidator = (_dataItem, formValueGetter) => {
    let result = {};
    result.Descripcion = [requiredValidator(formValueGetter('Descripcion')), descriptionLengthValidator(formValueGetter('Descripcion'))].filter(Boolean).reduce((current, acc) => current || acc, '');
    result.TituloActividad = [requiredValidator(formValueGetter('TituloActividad'))].filter(Boolean).reduce((current, acc) => current || acc, '');
    result.activityType = [requiredValidator(formValueGetter('activityType'))].filter(Boolean).reduce((current, acc) => current || acc, '');
    result.country = [requiredValidator(formValueGetter('country'))].filter(Boolean).reduce((current, acc) => current || acc, '');
    result.stakeholder = [requiredValidator(formValueGetter('stakeholder')),stakeholderValidator(formValueGetter('stakeholder'))].filter(Boolean).reduce((current, acc) => current || acc, '');
    return result;
  };
  return <SchedulerForm initialValues={initialValues} {...props} editor={CustomFormEditor} dialog={CustomDialog} validator={formValidator} />;
};