
import { MyCommandCellTopics } from "./topicsCommandCell";
import { Grid, GridColumn as Column, GridColumnMenuFilter, GridColumnMenuSort, GridToolbar } from "@progress/kendo-react-grid";
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import {ActiveCell, TopicsDateCell} from './GridCells';
import { process } from '@progress/kendo-data-query';
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Button } from '@progress/kendo-react-buttons'
import { DateTopicsEditor, TopicsDrowpdown, TrainerDrowpdown, TypeDrowpdown } from "./topicsSampleProducts";
import { FormAutoComplete } from "./form-components";
import { useDispatch, useSelector } from "react-redux";
import {  selectDetalleCapacitacion, setDetalleCapacitacion } from "../../redux/TNBSlice";
import { createContext, useCallback, useEffect, useRef, useState } from "react";
import { fileExcelIcon } from "@progress/kendo-svg-icons";
import { baseURL } from "../../Config";
const editField = "inEdit";


export const ColumnMenu = (props) => {
  return (
      <div>
          <GridColumnMenuSort {...props} />
          <GridColumnMenuFilter {...props} />
      </div>
  );
}



export const topicsContext=createContext(null)

export const TrainingTopicsTable = (props) => {
  const [dataDetailsTopics, setDataDetailsTopics] = useState([]);
  const [isloading,setLoading] =useState(true);
  const [take, setTake] = useState();
  const [skip, setSkip] = useState(0);
  const [sort, setSort] = useState([]);
  const [group, setGroup] = useState([]);
  const [filter, setFilter] = useState(null);
  const _export = useRef(null);
  const dispatch=useDispatch()
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const [inactiveTopicsTraining, setInactiveTopicsTraining] = useState();
  const capacitacion = useSelector(selectDetalleCapacitacion)

  useEffect(() => {

      try {
      const result=capacitacion.Temas
      setInactiveTopicsTraining(capacitacion.estado)
      setDataDetailsTopics(result)
      setLoading(false);
      } catch (error) {
        
      }
    
},[capacitacion]);

const dataState = {
  take,
  skip,
  sort,
  group,
  filter
};

const onDataStateChange = useCallback(
  (event) => {
      setTake(event.dataState.take);
      setSkip(event.dataState.skip);
      setSort(event.dataState.sort);
      setGroup(event.dataState.group);
      setFilter(event.dataState.filter);
  },
  [setTake, setSkip, setSort, setGroup]
);






  // modify the data in the store, db etc
  const remove = async dataItem => {
    await axios.post(`${baseURL}/api/v1/TNB/TrainingDetails/Topics/DeleteOne?id=${props.value}`, dataItem)
    .then((response)=>{
      const resultRemove=response.data
      setDataDetailsTopics(resultRemove.Temas)
    })
    const response = await axios.get(`${baseURL}/api/v1/TNB/Training?id=${props.value}`);
			dispatch(setDetalleCapacitacion(response.data))
  };
  const add = async dataItem => {
    dataItem.inEdit = false;
    await axios.post(`${baseURL}/api/v1/TNB/TrainingDetails/Topics/InsertOne?id=${props.value}`, dataItem)
    .then((response)=>{
      const resultAdd=response.data
      setDataDetailsTopics(resultAdd.Temas)
    });
    const response = await axios.get(`${baseURL}/api/v1/TNB/Training?id=${props.value}`);
			dispatch(setDetalleCapacitacion(response.data))
  };
  const update = async dataItem => {
    dataItem.inEdit = false;
    await axios.post(`${baseURL}/api/v1/TNB/TrainingDetails/Topics/UpdateOne?id=${props.value}`, dataItem)
    .then((response)=>{
      const resultUpdate=response.data
      setDataDetailsTopics(resultUpdate.Temas)
    })
    const response = await axios.get(`${baseURL}/api/v1/TNB/Training?id=${props.value}`);
			dispatch(setDetalleCapacitacion(response.data))    
  }

  // Local state operations
  const discard = () => {
    const newData = [...dataDetailsTopics];
    newData.splice(0, 1);
    setDataDetailsTopics(newData);
  };
  const cancel = dataItem => {
    setDataDetailsTopics(dataDetailsTopics.map(item => item.idActividad === dataItem.idActividad ? {
        ...item,
        inEdit: false
      } : item));
  };
  const enterEdit = dataItem => {
    setDataDetailsTopics(dataDetailsTopics.map(item => item.idActividad === dataItem.idActividad ? {
      ...item,
      inEdit: true
    } : item));
  };
  const itemChange = event => {
    const newData = dataDetailsTopics.map(item => item.idActividad === event.dataItem.idActividad ? {
      ...item,
      [event.field || '']: event.value
    } : item);
    setDataDetailsTopics(newData);
  };
  const addNew = () => {
    const newDataItemTopic = {
      inEdit: true,
      Discontinued: false
    };
    setDataDetailsTopics([newDataItemTopic, ...dataDetailsTopics]);
  };


  const CommandCell = props => <MyCommandCellTopics {...props} edit={enterEdit} remove={remove} add={add} discard={discard} update={update} cancel={cancel} editField={editField} />;

  const processedData = process(dataDetailsTopics, dataState);
  if (isloading) {
    return <div className='desktop-view-SDR'>
                <div id='reportContainerSDR'>
                <div className='centerDS'><CircularProgress/></div>
                </div>
            </div>
} else 
  return (    
  <>
    <ExcelExport data={dataDetailsTopics} ref={_export}>
    <Grid
        style={{
          height: "100%",
        }}

        data={processedData} onItemChange={itemChange} onDataStateChange={onDataStateChange} editField={editField}
      >
      <GridToolbar>
              <Button title="Add new" size={"small"} rounded={"medium"} themeColor={"base"}  onClick={addNew}  disabled={!inactiveTopicsTraining}>
                Agregar
              </Button>
              <Button
                      svgIcon={fileExcelIcon}
                      onClick={excelExport}
                      size={"small"} rounded={"medium"} themeColor={"success"} fillMode={"outline"} 
                  ></Button>
        </GridToolbar>
        <Column field={"idActividad"} title={"ID"} width="60px" editable={false}  columnMenu={ColumnMenu}  />
        <Column field={"nombreActividad"} title={"Tema"} columnMenu={ColumnMenu} cell={TopicsDrowpdown} />
        <Column field={"nombreEntrenadorActividad"} title={"Entrenador Tema"} columnMenu={ColumnMenu} cell={TrainerDrowpdown}/>
        <Column field={"tipoCapacitacion"} title={"Tipo Tema"} columnMenu={ColumnMenu}   cell={TypeDrowpdown} width="120%"/>
        <Column field={"duracion"} title={"Duración"} columnMenu={ColumnMenu} editor="numeric" width="120%"/>
        <Column field={"fecha"} title={"Fecha"} columnMenu={ColumnMenu} cell={DateTopicsEditor} width="100%"/>
        {inactiveTopicsTraining===true?<Column cell={CommandCell} title={"Acciones"} width="150%" />:null}
      </Grid>
      </ExcelExport>
    </>
  )
  
};
