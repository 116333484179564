import React, { useState } from 'react'
import './table.css'
import { Button } from '@progress/kendo-react-buttons'
import axios from 'axios'
import { thunkChargeInfoTicket } from '../../../../redux/helpDeskTicketSlice'
import { ChunkProgressBar } from "@progress/kendo-react-progressbars";
import { useDispatch } from 'react-redux'
import { baseURL } from '../../../../Config'

function selectValue(status){
  switch (status) {
    case 'Asignado':
      return 20
    case 'Proceso':
      return 40
    case 'Revisión':
      return 60
    case 'Aprobación':
      return 80
    case 'Cerrado':
      return 100
    case 'Espera':
      return 0
    default:
      return 0
  }}
export const Card = ({activity,setItem,handleSelect,id,isPartner,setToast,timeElapsedStatus}) => {
  const dispatch=useDispatch()
  const [valuePorcent, setValuePorcent] = useState(selectValue(activity.statusTask)) 

  
  const handleEditActivities = () => {
    setToast({snackbarOpen: true, message: `Actividad de ${activity.assignedTo.substring(0, 12)} seleccionada para Editar`, type: 'info'});
    setItem(activity)
    let e={selected:3}
    handleSelect(e)

  }
  const handleDeleteActivity = () => {
    let dataPerson = {
      timeElapsedStatus,
      commentOwner: {
        name: sessionStorage.getItem('name'),
        mail: sessionStorage.getItem('usr')
      }
    };
    
    axios.post(`${baseURL}/api/v1/helpDesk/deleteActivityV2`, {
      idActivity: activity.idActivity,
      ticketId: id,
      dataPerson
    })    
      .then(response => {
        // Handle successful deletion
        setToast({snackbarOpen: true, message: 'Actividad eliminada con éxito', type: 'success'});
        setItem(null);
        let e={selected:2}
        handleSelect(e)
        dispatch(thunkChargeInfoTicket({numTicket: id}));
      })
      .catch(error => {
        // Handle error
        setToast({snackbarOpen: true, message: 'No se pudo eliminar la actividad', type: 'error'});
        console.error('Error deleting activity:', error);
      });
      
    }
    const emptyStyles = {
      background: "#dbd7d7",
    };
    const getProgressStyles = (valuePercent) => {
      let background;
      switch (valuePercent) {
          case 20:
              background = "#FB6212";
              break;
          case 40:
              background = "#016FC4";
              break;
          case 60:
              background = "#D2298E";
              break;
          case 80:
              background = "#016FC4";
              break;
          case 100:
              background = "#00B500";
              break;
          default:
              background = "#FB6212";
      }
      return { background };
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}
  function createDateElements(fechaInicio, fechaFinal) {
    return (
        <>
            <p><strong>Fecha Inicio:</strong> {fechaInicio}</p>
            <p><strong>Fecha Final:</strong> {fechaFinal}</p>
        </>
    );
}
  
  // Uso de la función:
  const progressStyles = getProgressStyles(valuePorcent);
  const fechaInicio = formatDate(activity.dateStart.$date);
  const fechaFinal = formatDate(activity.dateEnd.$date);
  const dateElements = createDateElements(fechaInicio, fechaFinal);
  return (
    <div className="card-activities" onClick={handleEditActivities}>
        
        <h3>{activity.objectives}</h3>
        <h4><strong>Asignado A:</strong> {activity.assignedTo} - {activity.resolverEmail}</h4>
        <p><strong>Descripción:</strong> {activity.DescriptionActivity}</p>
        <p>ID Actividad:{activity.idActivity}</p>
        <div style={{display:'flex',justifyContent:'space-between'}}>
          {dateElements}
          <div>
            <p><strong>Estado:</strong> {activity.statusTask}</p>
            <ChunkProgressBar value={valuePorcent} progressStyle={progressStyles} emptyStyle={emptyStyles}/>
          </div>
        </div>
        {isPartner&&<Button fillMode={'outline'} themeColor={'error'}  onClick={handleDeleteActivity} >Eliminar</Button>}
    </div>
  )
}
