import React, {useState,useEffect} from 'react';
import { Scheduler, DayView,  MonthView, WorkWeekView } from '@progress/kendo-react-scheduler';
import { customModelFields, displayDate} from './data';

import { FormWithCustomEditor } from './custom-form';
import {ZonedDate } from "@progress/kendo-date-math";
import "@progress/kendo-date-math/tz/all";
import axios from 'axios'
import { CustomEditItem } from './custom-item';
import { baseURL } from '../../Config';


export const TimeTrackingLeader = () => {
  const date = new Date();
  const result = ZonedDate.fromLocalDate(date, 'America/Bogota')
  const [isloading,setLoading] = useState(true);
  const [results, setResults] = useState([]);
  const email = sessionStorage.getItem('usr')


  useEffect(() => {
    const loadGet = async () => {
      const response = await axios.get(`${baseURL}/api/v1/TReporting/TRActivityLeader?id=${email}`);
      const test=response.data
      setResults(test.map(dataItem => ({
        ...dataItem,
        Start: new Date(dataItem.Start),
        End: new Date(dataItem.End)
      })))
      setLoading(false);
    }
    loadGet();
},[isloading]);

console.log(results)
console.log(isloading)


const handleDataChange = ({ created, updated, deleted }) => {
    if(created[0]!==undefined){
      axios.post(`${baseURL}/api/v1/TReporting/TimeReporting?id=${email}`, created[0]) 
    } else if(deleted[0]!==undefined){
      axios.post(`${baseURL}/api/v1/TReporting/TRActivity?id=${email}`, deleted[0])
    } else {
      console.log(updated[0].ActivityID)
    }
    return(
      setLoading(true)
    )
      }


    return (
      <div>
      <br/>
      <p>Recuerda que en este lugar agregaras las horas que trabajaste en la semana. Es importante que las reportes lo mas cercano a la realidad porque esto nos dara herrramientas para visibilizar las actividades que realizamos, poder ayudarles a balancear cargas y programar entrenamientos entre otros.</p>  
      <br/>
      <Scheduler timezone={'America/Bogota'} 
                    height={820} modelFields={customModelFields}
                      data={results}
                      onDataChange={handleDataChange}
                      editable={{
                        add: true,
                        remove: true,
                        drag: true,
                        resize: true,
                        select: true,
                        edit: true,
                      }}
                      editItem={CustomEditItem}
                      defaultDate={displayDate}
                      form={FormWithCustomEditor}
                    >

                      <WorkWeekView/>
                      <DayView numberOfDays={1} />
                      <MonthView />
                    </Scheduler></div>
    );
};
