import { useMemo, useState } from 'react';
import './styleControlCenter.css';
import { useSelector } from 'react-redux';
import { selectData } from '../../../redux/viewBISlice';
import { WrongMessage } from './WrongMessage';
import { Loader } from '@progress/kendo-react-indicators';
import { CorrectMessage } from './CorrectMessage';
import useAxios from '../../hooks/useAxios';
import { Slide } from "@progress/kendo-react-animation";
import { baseURL } from '../../../Config';

export const DetailsRow = ({ dataItem,datasetId }) => {


const url = datasetId ? `${baseURL}/api/v1/pbi/Reports/Refresh/Details?datasetId=${datasetId}&refreshId=${dataItem.requestId}` : null;

const { data, isLoading, error } = useAxios(url);


  if (isLoading) {
    return <Loader type="converging-spinner" size="large" />;
  }
  if (error) {
    return <h1>Error al traer la información, intentalo más tarde</h1>;
  }
  

  return (
    <Slide>
    <div>
      <div style={{display:'flex'}}>
        {dataItem.status === "Failed" ? <h4>Detalles del Error</h4> : <h4>Detalles de la Actualización</h4>}        
      </div>   
      {dataItem.status === "Failed" ? <WrongMessage data={data} /> : <CorrectMessage data={data} />}
    </div>
    </Slide>
  );
};
