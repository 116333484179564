import { AutoComplete, MultiSelect } from "@progress/kendo-react-dropdowns";
import { FieldWrapper } from "@progress/kendo-react-form";
import { useState } from "react";
import { searchPeople } from "../../../Graph";
import { Hint, Error } from "@progress/kendo-react-labels";
import { Loader } from "@progress/kendo-react-indicators";
import { useMsal } from '@azure/msal-react'; // Importa useMsal para manejar MSAL
import { loginRequest } from "../../../Config";



export const AddWhoWillSee = (fieldRenderProps) => {
    const { instance, accounts } = useMsal(); // Obtén la instancia y las cuentas

    const [loading, setLoading] = useState(false);
    const [listNames, setListNames] = useState([]);
    const [timer, setTimer] = useState(null);

    // Función para obtener el token de manera silenciosa
    async function getToken() {
        try {
            const tokenResponse = await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0] // Selecciona la cuenta del usuario
            });
            return tokenResponse.accessToken;
        } catch (error) {
            console.error('Error acquiring token silently:', error);
        }
    }

    // Función para buscar personas
    async function search(name) {
        if (name !== '') {
            setLoading(true);
            const token = await getToken();
            if (token) {
                searchPeople(token, name).then(response => {
                    setListNames(response.value?.map(objeto => objeto.displayName));
                    setLoading(false);
                }).catch(error => {
                    console.error("No se puede conectar con Graph", error);
                    setLoading(false);
                });
            }
        }
    }

    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        type,
        value,
        optional,
        ...others
    } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';

    // Manejador de búsqueda con retardo
    const handleSearch = (event) => {
        fieldRenderProps.onChange({
            value: event.value,
        });
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            search(event.filter.value);
        }, 300); // 300 ms de espera antes de ejecutar la búsqueda
        setTimer(newTimer);
    };

    return (
        <FieldWrapper>
            <div>Ingresa el nombre de las personas que verán la información:</div>
            <div style={{ display: 'flex' }}>
                <MultiSelect
                    valid={valid} type={type} id={id} disabled={disabled} ariaDescribedBy={`${hintId} ${errorId}`} value={value} {...others}
                    data={listNames}
                    filterable={true}
                    onFilterChange={handleSearch}
                    style={{
                        width: "450px",
                    }}
                    placeholder="Escribe el nombre..."
                />
                {loading && <Loader size="medium" />}
            </div>
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
        </FieldWrapper>
    );
}
