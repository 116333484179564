import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseURL } from '../Config';

export const TimeReportingSlice = createSlice({
    name: 'TimeReporting',
    initialState: {
        activities:null,
        isLoadingAllActivities:false
    },
    reducers: {

        allActividiesPerWeek:(state,action)=>{
            state.activities=action.payload;
        },
        changeIsLoadingAllActivities:(state,action)=>{
            state.isLoadingAllActivities=action.payload
        }
    }
    
});
export const thunkExtractActivities=(email=undefined)=>{
    return async (dispatch,getState)=>{
        dispatch(changeIsLoadingAllActivities(true));

            const response = await axios.get(`${baseURL}/api/v1/TReporting/TRActivity?id=${email}`);
            let test=response.data          
        dispatch(allActividiesPerWeek(test))
        dispatch(changeIsLoadingAllActivities(false));
    }
}
export const { allActividiesPerWeek,changeIsLoadingAllActivities } = TimeReportingSlice.actions; 

export const selectAllActivities = state => state.TimeReporting.activities;

export const selectLoadingActivities = state => state.TimeReporting.isLoadingAllActivities;
export default TimeReportingSlice.reducer