export const config = {
    auth: {
        clientId: process.env.REACT_APP_API_PBI_CLIENT_ID,
        redirectUri: undefined,
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_API_PBI_TENANT_ID}`
    },

    cache: {
        cacheLocation: 'SessionStorage',
        storeAuthStateInCookie: true,
    }
};

export const baseURL = process.env.REACT_APP_URL_BACKEND

export const redirect= process.env.REDIRECT_URI


export const loginRequest = {
    scopes: ['.default'],
}


export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/beta/me/',
    graphPhotoEndpoint: 'https://graph.microsoft.com/v1.0/me/photo/$value',
    graphCalendarEndpoint: 'https://graph.microsoft.com/beta/me/events?$select=subject,body,bodyPreview,organizer,attendees,start,end,location',
    graphGroupEndpoint: 'https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true',
    graphMyProfilepoint: 'https://graph.microsoft.com/v1.0/me',
    graphSearchPeople: 'https://graph.microsoft.com/beta/users?$search='
}






