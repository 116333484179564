import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseURL } from '../Config';

export const helpDeskSlice = createSlice({
    name: 'helpDesk',
    initialState: {
        InfoTicket:{},
        isLoadingInfoTicket:false,
        errorAboutChangeTicket:false
    },
    reducers: {

        setInfoTicket:(state,action)=>{
            state.InfoTicket=action.payload;
        },
        changeIsLoadingInfoTicket:(state,action)=>{
            state.isLoadingInfoTicket=action.payload;
        },
        setErrorAboutChangeTicket:(state,action)=>{
            state.errorAboutChangeTicket=action.payload;
        },
        setEmptyInfoTicket:(state)=>{
            state.InfoTicket={};
        },
    }
    
});
export const thunkChargeInfoTicket=(DataToSend)=>{
    return async (dispatch,getState)=>{
        dispatch(changeIsLoadingInfoTicket(true));

            const response = await axios.post(`${baseURL}/api/v1/helpDesk/fullTicket`,DataToSend);
            let test=response.data[0]          
        dispatch(setInfoTicket(test))
        dispatch(changeIsLoadingInfoTicket(false));
    }
}
export const { setInfoTicket,changeIsLoadingInfoTicket,setEmptyInfoTicket} = helpDeskSlice.actions; 

export const selectGetInfoTicket = state => state.helpDeskTicket.InfoTicket;

export const selectGetLoadingInfoTicket = state => state.helpDeskTicket.isLoadingInfoTicket;
export default helpDeskSlice.reducer