import React from 'react';
import '../components/status-card/statuscard.css';
import {Link} from 'react-router-dom';
import {SiGoogleclassroom} from 'react-icons/si';
import {MdAppRegistration, MdOutlineClass} from 'react-icons/md';
import {FaRegNewspaper, FaStar, FaStarHalfAlt} from 'react-icons/fa';
import { RiMedalLine } from "react-icons/ri";
import { SiDatabricks } from "react-icons/si";
import { redirect } from '../Config';
import { GrUserAdmin } from "react-icons/gr";
export const Learning = () => {
	console.log("redireccionamiento:",redirect)
	return (
		<div>
			<h2 className="page-header">Learning & Development</h2>
			<h3 className="page-subheader">Learning & Development</h3>
			<section className="status-cards-container">
				<div className="col-6-SDR">
					<div className="status-card">
						<div className="status-card__icon">
							<SiGoogleclassroom />
						</div>
						<div className="status-card__info">
							<Link to="/Learning/Tools/Management">Management</Link>
						</div>
					</div>
				</div>
			</section>
			<h3 className="page-subheader">Herramientas TNB</h3>
			<section className="status-cards-container">
				<div className="col-6-SDR">
					<div className="status-card">
						<div className="status-card__icon">
							<MdOutlineClass />
						</div>
						<div className="status-card__info">
							<Link to="/LobbyReports/Reportes%20por%20Área%2FEntrenamiento%20y%20Valoraciones%2FValoracion%20y%20Comunicaciones%2FTNB">Reports</Link>
						</div>
					</div>
				</div>
				<div className="col-6-SDR">
					<div className="status-card">
						<div className="status-card__icon">
							<MdAppRegistration />
						</div>
						<div className="status-card__info">
							<Link to="/Training/Tools/TNBApp">TNB App</Link>
						</div>
					</div>
				</div>
			</section>
			<h3 className="page-subheader">Alianzas</h3>
			<section className="status-cards-container">
				<div className="col-6-SDR">
					<div className="status-card">
						<div className="status-card__icon">
            				<RiMedalLine />
						</div>
						<div className="status-card__info">
							<Link to="/Template/Grid/Seguimientos Landing">
								Consumo Alianzas
							</Link>
						</div>
					</div>
				</div>
				<div className="col-6-SDR">
					<div className="status-card">
						<div className="status-card__icon">
            				<GrUserAdmin />
						</div>
						<div className="status-card__info">
							<Link to="/Template/Grid/Seguimientos Landing Admin">
								Administrador Alianzas
							</Link>
						</div>
					</div>
				</div>
			</section>
			<h3 className="page-subheader">Valoraciones y Comunicaciones</h3>
			<section className="status-cards-container">
				<div className="col-6-SDR">
					<div className="status-card">
						<div className="status-card__icon">
							<FaStar />
							<FaStar />
							<FaStarHalfAlt />
						</div>
						<div className="status-card__info">
							<Link to="/Training/Reports/Valoraciones">
								Valoraciones / Top Performance
							</Link>
						</div>
					</div>
				</div>
				<div className="col-6-SDR">
					<div className="status-card">
						<div className="status-card__icon">
							<FaRegNewspaper />
						</div>
						<div className="status-card__info">
							<Link to="/Training/Reports/Comunicaciones">Comunicaciones</Link>
						</div>
					</div>
				</div>
				<div className="col-6-SDR">
					<div className="status-card">
						<div className="status-card__icon">
							<SiDatabricks />
						</div>
						<div className="status-card__info">
							<Link to="/Reports/Databricks/Pricing">Reportes de Databricks</Link>
						</div>
					</div>
				</div>

				<div className="col-6-SDR">
					<div className="status-card">
						<div className="status-card__icon">
							<img src="https://www.holcim.com.co/themes/custom/corporate_country/components/header/images/holcim_logo_color.svg" alt="logo Holcim" height='40px' width='90px' />
						</div>
						<div className="status-card__info">
							<Link to="/Holcim/Reports">Holcim</Link>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};
