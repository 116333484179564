export function ExtractExecutionTimes(executions=[]) {
    if (!Array.isArray(executions)) {
        
        return [];
      }
    
      // Función auxiliar para calcular la duración en segundos
      const calculateDurationInSeconds = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        // Verificar si alguna de las fechas es inválida
        if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
          console.error('Invalid date provided for calculation.');
          return 0;
        }
        return (endDate - startDate) / 1000;
      };
    
      // Filtrar ejecuciones completadas
      const completedExecutions = executions.filter(exec => {
        // Verificar si exec es un objeto y tiene la propiedad status
        return typeof exec === 'object' && exec !== null && exec.status === 'Completed';
      });

// Calculate execution times in seconds
const executionTimesInSeconds = completedExecutions.map(exec => calculateDurationInSeconds(exec.startTime, exec.endTime));

// Verificar si executionTimesInSeconds está vacío
const hasExecutions = executionTimesInSeconds.length > 0;

const maxExecutionTimeInSeconds = hasExecutions ? Math.max(...executionTimesInSeconds) : 0;
const minExecutionTimeInSeconds = hasExecutions ? Math.min(...executionTimesInSeconds) : 0;
const avgExecutionTimeInSeconds = hasExecutions ? executionTimesInSeconds.reduce((sum, time) => sum + time, 0) / executionTimesInSeconds.length : 0;

// Asegurarse de que los valores son numéricos después de usar .toFixed()
const result = {
  maxExecutionTimeInSeconds: +maxExecutionTimeInSeconds.toFixed(2),
  minExecutionTimeInSeconds: +minExecutionTimeInSeconds.toFixed(2),
  avgExecutionTimeInSeconds: +avgExecutionTimeInSeconds.toFixed(2),
  successPercentage: +(completedExecutions.length / executions.length * 100).toFixed(2)
};


return result;
}
      
