import React, { useState, useEffect } from 'react';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import { commentIcon, envelopIcon, volumeDownIcon, minusSmIcon } from "@progress/kendo-svg-icons";
import { ColumnNotaCredito } from "./TableColumns/columnNotaCredito";
import { useUserService } from '../../services/userService';
import axios from 'axios';
import { ColumnActions } from './TableColumns/columnActions';
import { RowDetails } from './TableColumns/rowDetails';
import { ColumnTipoDeGestion } from './TableColumns/columnTipoDeGestion';
import { Chip, ChipList } from "@progress/kendo-react-buttons";
import { ResumeTable } from './CustomComponents/resumeTable';
import { ColumnValor } from './TableColumns/columnValor';
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Popup } from "@progress/kendo-react-popup";

const AgentPanel = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [savedPromises, setSavedPromises] = useState([]);
  const [userEmail, setUserEmail] = useState(sessionStorage.getItem("usr"));
  const [editMode, setEditMode] = useState({});
  const [editingData, setEditingData] = useState({});
  const [showDetails, setShowDetails] = useState({});
  const [isAdmin, setIsAdmin] = useState(false)
  const { userProfile } = useUserService();
  const [value, setValue] = React.useState(new Date());
  const [paginationConfig, setPaginationConfig] = useState([]);
  const [compiledData, setCompiledData] = useState([])
  const [searchText, setSearchText] = useState(null)
  const [activeView, setActiveView] = useState("general")
  const [selectedCountryValue, setSelectedCounrtyValue] = useState("")
  const [IVA, setIVA] = useState(undefined)
  const [promisesResume, setPromisesResume] = useState({
    daily: {},
    monthly: {},
  });

  useEffect(() => {
    let selectedCountry = localStorage.getItem("selectedCountryName")
    setSelectedCounrtyValue(selectedCountry)
  }, [])
  const [chartsData, setChartsData] = useState([])
  const tipoGestion = [
    {
      text: " = Renov. Chat",
      svgIcon: commentIcon,
    },
    {
      text: " = Renov. Email",
      svgIcon: envelopIcon,
    },
    {
      text: " = Renov. Telefonica",
      svgIcon: volumeDownIcon,
    },
    {
      text: " = Desconicido",
      svgIcon: minusSmIcon,
    },
  ];
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    setShow(false);
  }, []);
  const onClick = () => {
    setShow(!show);
  };
  const getBoardConfig = (email) => {
    axios.get("/api/siignalGetPaymentPromiseConfig").then(res => {
      let adminEmails = []
      Object.keys(res.data).map(country => {
        if (country != "_id") {
          adminEmails = [...adminEmails, ...res.data[country].configPanel]
        }
      })
      if (adminEmails.includes(userEmail)) {
        setIsAdmin(true)
        
      }
    })
  }
  //Date Picker handler
  const handleChange = (event, page = 1, elementsPerPage = paginationConfig.elementsPerPage2) => {
    let promise_date = event.value
    promise_date = promise_date.toString()
    promise_date = promise_date.replace("GMT-0500", "GMT-0000")
    promise_date = new Date(promise_date)
    promise_date = promise_date.toISOString()
    promise_date = promise_date.split('T')[0]
    setValue(event.value);
    getPromisedByDate(page, elementsPerPage, promise_date)
  };
  //Get promises by Date
  const getPromisedByDate = async (page = 1, elPerPage = 50, promise_date) => {
    try {
      // Obtenemos el correo de usuario
      const email = isAdmin ? "admin" : sessionStorage.getItem("usr");
      setIsLoading(true); // Iniciamos el estado de carga
  
      // Realizamos la llamada al API
      const { data } = await axios.get(`/api/siignalGetComoVamosDate/${email}/${page}/${elPerPage}/${promise_date}`);
      // Verificamos si la respuesta tiene los datos esperados
      if (!data) {
        throw new Error('No se recibieron datos del servidor');
      }
  
      const { compiled = [], elementsPerPage, page: currentPage, totalElements, data: responseData } = data;
      // // Si no hay datos compilados, lanzamos un error
      // if (compiled.length === 0) {
      //   throw new Error('No se encontraron datos compilados');
      // }
  
      // Actualizamos el estado con la configuración de la paginación
      setPaginationConfig({
        elementsPerPage,
        page: currentPage,
        totalElements
      });
  
      // Asignamos los datos compilados
      // setCompiledData(compiled[0]);
      // Parseamos la data de la respuesta
      const savedPromises = JSON.parse(responseData);
      // Asignamos los datos de las promesas guardadas
      setSavedPromises(savedPromises);
      
    } catch (err) {
      // Manejamos el error de forma más detallada
      console.error("Error al cargar los datos: ", err);
      alert(`Error al cargar configuración: ${err.message || 'Ocurrió un error desconocido'}`);
    } finally {
      // Indicamos que ya no estamos en carga, independientemente de si hubo error o no
      setIsLoading(false);
    }
  }
  

  useEffect(() => {
    if (isAdmin != undefined) {
      loadSavedPromises();
    }
  }, [isAdmin])


  const getPromisedByUser = (page = 1, elPerPage = 50) => {
    let email = (isAdmin) ? "admin" : sessionStorage.getItem("usr");
    setIsLoading(true);
    axios.get(`/api/siignalGetComoVamos/${email}/${page}/${elPerPage}/${searchText}`).then(res => {
      let savedPromises = res.data;
      setPaginationConfig({
        elementsPerPage: savedPromises.elementsPerPage,
        page: savedPromises.page,
        totalElements: savedPromises.totalElements
      })
      // setCompiledData(savedPromises.compiled[0])
      savedPromises = JSON.parse(savedPromises.data)
      setSavedPromises(savedPromises);
    }).catch(err => {
      console.log(err);
      alert("Error al cargar configuración")
    });
  }
  const getPromisedByLeader = (page = 1, elPerPage = 50) => {
    let email = sessionStorage.getItem("usr")
    axios.get(`/api/siignalGetPaymentPromisesByLeader/${email}/${page}/${elPerPage}/${searchText}`).then(res => {
      let savedPromises = res.data;
      if (savedPromises.message != undefined) {
        setPaginationConfig({
          elementsPerPage: savedPromises.elementsPerPage,
          page: 1,
          totalElements: 0
        })
        savedPromises = []
        setSavedPromises(savedPromises);
      } else {
        setPaginationConfig({
          elementsPerPage: savedPromises.elementsPerPage,
          page: savedPromises.page,
          totalElements: savedPromises.totalElements
        })
        savedPromises = JSON.parse(savedPromises.data)
        setSavedPromises(savedPromises);
      }
    }).catch(err => {
      console.log(err);
      alert("Error al cargar configuración")
    });
  }

  const loadSavedPromises = (page = 1, elementsPerPage = paginationConfig.elementsPerPage) => {
    getPromisedByUser(page, elementsPerPage);
  }

  const generateCustomClass = (item) => {
    let classesToAdd = [];

    const promiseDate = new Date(item.dynamicInputsData.tablero.fechaCompromisoExtendido)
    const currentDate = new Date();
    const nextTwoDays = new Date()
    nextTwoDays.setDate(nextTwoDays.getDate() + 2)

    if (item.dynamicInputsData.tablero.estado == "Pagado") {
      classesToAdd.push("renewalsTree-promiseIsPaid")
    } else if (item.dynamicInputsData.tablero.estado == "Vencido") {
      classesToAdd.push("renewalsTree-promiseIsExpired")
    } else if (promiseDate >= currentDate && promiseDate <= nextTwoDays) {
      classesToAdd.push("renewalsTree-promiseIsAlmostExpired")
    }
    return classesToAdd.join(" ");
  }

  const viewDetails = (index, data) => {
    setShowDetails((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  }

  const toggleEditMode = (index, data) => {
    setEditingData((prevState) => ({
      ...prevState,
      [index]: data,
    }));

    setEditMode((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const updatePromise = (index) => {
    let updatedRow = editingData[index]
    axios
      .put(`/api/siignalUpdatePaymentPromise/${JSON.stringify(updatedRow._id)}`, updatedRow)
      .then((res) => {
        loadSavedPromises(paginationConfig.page, paginationConfig.elementsPerPage);
        toggleEditMode(index);
      })
      .catch((err) => {
        console.log(err);
        alert("Error al guardar cambios");
      });
  };

  const deletePromise = (index, item) => {
    const elementId = item._id["$oid"]

    const deleteData = {
      _id: elementId,
      deletedBy: userEmail,
      deletedDate: new Date(),
      deletedRecord: item
    }

    axios.put(`/api/siignalDeletePaymentPromise/${elementId}`, deleteData).then(res => {
      alert(res.data.message)
      loadSavedPromises(paginationConfig.page, paginationConfig.elementsPerPage);
    }).catch(err => {
      alert("Error al eliminar")
    })
  };

  useEffect(() => {
    let email = sessionStorage.getItem("usr");
    setUserEmail(email);
    getBoardConfig(email);
  }, []);

  useEffect(() => {
    if (savedPromises != null) {
      setIsLoading(false);
      if (savedPromises.length != 0) {
        calculateResume()
      }
    }
  }, [savedPromises]);

  const generateDataForCharts = (data) => {
    const categoriesNameMap = {
      daily: "Diario",
      monthly: "Mensual"
    };
    const seriesNameMap = {
      totalPromised: "Total",
      totalPayed: "Recaudado",
      totalOutdated: "Vencido",
      totalPromises: "Promesas",
      totalNC: "NC Total",
      totalNCDone: "NC Creadas"
    }
    let tempChartsData = Object.keys(data).map(group => {
      const categories = Object.keys(data[group]);
      const seriesData = categories.reduce((result, dataPoint) => {
        const dataPoints = Object.keys(data[group][dataPoint]);
        dataPoints.forEach(dataPointName => {
          if (!result[dataPointName]) {
            result[dataPointName] = [];
          }
          result[dataPointName].push(data[group][dataPoint][dataPointName]);
        });
        return result;
      }, {});
            
      let result = {
        chartName: '',
        categories,
        series: [{
          name: categoriesNameMap[group],
          data: seriesData
        }]
      };

      let newSeriesData = Object.entries(result.series[0].data).map(([serie, data]) => ({
        name: seriesNameMap[serie],
        data: data
      }));

      result.series = newSeriesData;

      return result
    });
    setChartsData(tempChartsData)
  }

  const calculateResume = () => {
    const monthNames = [
      "Enero", "Febrero", "Marzo", "Abril",
      "Mayo", "Junio", "Julio", "Agosto",
      "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    let tempResumeData = {
      daily: {},
      monthly: {},
    }
    savedPromises.forEach((item, index) => {
      const tableroData = item.dynamicInputsData.tablero;
      let promiseDate = new Date(tableroData.fechaGestion)

      const currentDay = tableroData.fechaGestion.split(" ")[0]
      let currentDayData = tempResumeData.daily[currentDay]
      if (currentDayData == undefined) {
        tempResumeData.daily[tableroData.fechaGestion.split(" ")[0]] = {
          totalPromised: tableroData.totalPayment,
          totalPayed: (tableroData.estado == 'Pagado') ? tableroData.totalPayment : 0,
          totalOutdated: (tableroData.estado == "Vencido") ? tableroData.totalPayment : 0,
          totalPromises: 1,
          totalNC: (tableroData.aplicaNC == "Si") ? 1 : 0,
          totalNCDone: (tableroData.aplicaNC == "Si" && tableroData.idNotaCredito.trim().length != 0) ? 1 : 0
        }
      } else {
        currentDayData.totalPromised += tableroData.totalPayment
        currentDayData.totalPayed += (tableroData.estado == 'Pagado') ? tableroData.totalPayment : 0
        currentDayData.totalOutdated += (tableroData.estado == "Vencido") ? tableroData.totalPayment : 0
        currentDayData.totalPromises += 1
        currentDayData.totalNC += (tableroData.aplicaNC == "Si") ? 1 : 0
        currentDayData.totalNCDone += (tableroData.aplicaNC == "Si" && tableroData.idNotaCredito.trim().length != 0) ? 1 : 0
      }
      const currentMonth = monthNames[promiseDate.getMonth()]
      let currentMonthData = tempResumeData.monthly[currentMonth]
      if (currentMonthData == undefined) {
        tempResumeData.monthly[currentMonth] = {
          totalPromised: tableroData.totalPayment,
          totalPayed: (tableroData.estado == 'Pagado') ? tableroData.totalPayment : 0,
          totalOutdated: (tableroData.estado == "Vencido") ? tableroData.totalPayment : 0,
          totalPromises: 1,
          totalNC: (tableroData.aplicaNC == "Si") ? 1 : 0,
          totalNCDone: (tableroData.aplicaNC == "Si" && tableroData.idNotaCredito.trim().length != 0) ? 1 : 0
        }
      } else {
        currentMonthData.totalPromised += tableroData.totalPayment
        currentMonthData.totalPayed += (tableroData.estado == 'Pagado') ? tableroData.totalPayment : 0
        currentMonthData.totalOutdated += (tableroData.estado == "Vencido") ? tableroData.totalPayment : 0
        currentMonthData.totalPromises += 1
        currentMonthData.totalNC += (tableroData.aplicaNC == "Si") ? 1 : 0
        currentMonthData.totalNCDone += (tableroData.aplicaNC == "Si" && tableroData.idNotaCredito.trim().length != 0) ? 1 : 0
      }
    })
    setPromisesResume(tempResumeData)
    generateDataForCharts(tempResumeData)
  }

  const formatData = (data) => {
    const tab = '    ';
    const formattedData = [
      'Información del Usuario:',
      `${tab}Usuario: ${data["Nombre del cliente"] || ''}`,
      `${tab}NIT: ${data.NIT || ''}`,
      `${tab}Contacto: ${data.Contacto || ''}`,
      `${tab}Cargo: ${data.Cargo || ''}`,
      `${tab}Teléfono: ${data.Telefono || ''}`,
    ];

    if (data.tablero) {
      formattedData.push(
        `Fecha de Gestión: ${data.tablero.fechaGestion || ''}`,
        'Productos:'
      );
      if (data.tablero.pagos) {
        data.tablero.pagos.forEach((pago, index) => {
          if (pago[0] != 0) {
            formattedData.push(`${tab}Producto ${index + 1}: $${pago[0] || ''}`);
          }
        });
      }
      formattedData.push(
        `Tipo de Gestión: ${data.tablero.tipoGestion2 || ''}`,
      )
      if (data.tablero.tipoGestion2 == "Descuento") {
        formattedData.push(
          `${tab}Porcentaje: ${data.tablero.descuento || ''}%`,
        );
      }
      if (data.tablero.tipoGestion2 == "Cuotas" && data.tablero.cuotas) {
        formattedData.push('Cuotas:');
        data.tablero.cuotas.forEach((cuota, index) => {
          if (cuota[0] != "") {
            let subTotal = data.tablero.pagos.reduce((acc, curr) => {
              let value = parseFloat(curr[0].toString().split(".").join(""))
              let thisEl = value;
              acc = parseFloat(acc) + thisEl;
              return acc;
            }, 0);

            let ivaTotal = data.tablero.pagos.reduce((acc, curr) => {
              let hasIVA = curr[1]
              let value = parseFloat(curr[0].toString().split(".").join(""))
              let thisEl = hasIVA ? value * (IVA/100) : 0;
              acc = parseFloat(acc) + thisEl;
              return acc;
            }, 0);
            let payment = (((parseFloat(subTotal) + parseFloat(ivaTotal)) * parseFloat(cuota[0] / 100)).toFixed()).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            formattedData.push(`${tab}Cuota ${index + 1}: ${cuota[0] || ''}% - $${payment} (${cuota[1] || ''})`);
          }
        });
      }
      formattedData.push(
        `Fecha de Compromiso: ${data.tablero.fechaCompromiso || ''}`,
        `Fecha de Compromiso Extendido: ${data.tablero.fechaCompromisoExtendido || ''}`,
        `Aplica Nota de Crédito: ${data.tablero.aplicaNC || ''}`
      );
      if (data.tablero.aplicaNC == "Si" && data.tablero.tipoNC) {
        formattedData.push(
          `${tab}${tab}Tipo de Nota de Crédito: ${data.tablero.tipoNC || ''}`,
          `${tab}${tab}Motivo de Nota de Crédito: ${data.tablero.motivoNC || ''}`,
          `${tab}${tab}Valor de Nota de Crédito: ${data.tablero.valorNC || ''}`,
          `${tab}${tab}ID de Nota de Crédito: ${data.tablero.idNotaCredito || ''}`
        );
      }
      formattedData.push(
        `${tab}${tab}Notas: ${data.tablero.notas || ''}`,
        `${tab}${tab}Total de Pago: ${data.tablero.totalPayment || ''}`
      );
    }

    return formattedData.join('\n');
  };

  const generatePageNumbers = (maxPagesToShow = 11) => {
    let currentPage = paginationConfig.page;
    let totalElements = paginationConfig.totalElements;
    let elementsPerPage = paginationConfig.elementsPerPage;
    const totalPages = Math.ceil(totalElements / elementsPerPage);

    currentPage = Math.min(Math.max(currentPage, 1), totalPages);

    let startPage, endPage;

    if (totalPages <= maxPagesToShow) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
      if (currentPage <= halfMaxPagesToShow) {
        startPage = 1;
        endPage = maxPagesToShow;
      } else if (currentPage + halfMaxPagesToShow >= totalPages) {
        startPage = totalPages - maxPagesToShow + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - halfMaxPagesToShow;
        endPage = currentPage + halfMaxPagesToShow;
      }
    }
    let tempResult = (currentPage < 6) ? [] : ['Primera']

    tempResult = [
      ...tempResult,
      ...Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)
    ];
    if (currentPage < totalPages - 6) {
      tempResult.push('Ultima')
    }

    return tempResult.map(el => {
      return {
        text: (el == 'Ultima') ? `${el} (${totalPages})` : el,
        themeColor: (el == currentPage) ? 'info' : 'base',
        onClick: () => {
          handlePaginationButtonClick(el)
        }
      }
    })
  };

  const handlePaginationButtonClick = (target) => {
    let newPage;
    switch (target) {
      case 'Primera':
        newPage = 1;
        break;
      case 'Ultima':
        newPage = Math.ceil(paginationConfig.totalElements / paginationConfig.elementsPerPage);
        break;
      default:
        newPage = target;
    }

    if (activeView == "general") {
      getPromisedByUser(newPage, paginationConfig.elementsPerPage);
    } else {
      getPromisedByLeader(newPage, paginationConfig.elementsPerPage)
    }
  };
  const [anchorAlign, setAnchorAlign] = React.useState({
    horizontal: "left",
    vertical: "top",
  });
  const [popupAlign, setPopupAlign] = React.useState({
    horizontal: "left",
    vertical: "top",
  });
  const [anchorAlign2, setAnchorAlign2] = React.useState({
    horizontal: "right",
    vertical: "top",
  });
  const [popupAlign2, setPopupAlign2] = React.useState({
    horizontal: "right",
    vertical: "top",
  });
  
  return (
    <GridLayout cols={[{ width: "auto", width: "auto", width: "auto", width: "auto" }]}
      gap={{ rows: 10, cols: 10, }}>
      <GridLayoutItem colSpan={1}>
        <h6>
          <button onClick={() => loadSavedPromises()}>🔄Actualizar</button> <b>Configuración del Tablero</b>
        </h6>
      </GridLayoutItem>

      {isLoading ? (
        <>Cargando...</>
      ) : (
        <>
            {/* <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={onClick}
              ref={anchor}
            >
              {show ? "Esconder resumen diario y mensual" : "Mostrar resumen diario y mensual"}
            </button>
          <GridLayoutItem>
            <GridLayout>
              <GridLayoutItem>
                <Popup anchorAlign={anchorAlign} popupAlign={popupAlign} anchor={anchor.current} show={show} popupClass={"popup-content"}>
                  <ResumeTable data={compiledData.daily}/>
                </Popup>
              </GridLayoutItem>
              <GridLayoutItem>
                <Popup anchorAlign={anchorAlign2} popupAlign={popupAlign2} anchor={anchor.current} show={show} popupClass={"popup-content"}>
                  <ResumeTable data={compiledData.monthly}/>
                </Popup>
              </GridLayoutItem>
            </GridLayout>
          </GridLayoutItem> */}

          <GridLayoutItem>
            <div className='tipoGestionLegend'>
              <ChipList
                data={tipoGestion}
                chip={(props) => (
                  <Chip
                    {...props}
                    text={props.dataItem.text}
                    svgIcon={props.dataItem.svgIcon}
                  />
                )}
              />
            </div>
          </GridLayoutItem>

          <GridLayoutItem>
            <GridLayout
              cols={[{ width: "35%" }, { width: "65%" }]}>
              <GridLayoutItem>
                <GridLayout
                  cols={[{ width: "50%" }, { width: "50%" }]}>
                  <GridLayoutItem>
                    <div className='mxRenewalsInputCont'>
                      <input
                        className='mxRenewalsInput'
                        type="text"
                        placeholder="Buscar..."
                        value={searchText}
                        onChange={(event) => { setSearchText((event.target.value == "") ? null : event.target.value) }} />
                      <Button
                        onClick={() => {
                          if (activeView == "general") {
                            getPromisedByUser(paginationConfig.page, paginationConfig.elementsPerPage);
                          } else {
                            getPromisedByLeader(paginationConfig.page, paginationConfig.elementsPerPage)
                          }
                        }}>🔍</Button>
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <ButtonGroup>
                      <Button
                        className='siignalBtn info'
                        onClick={(page = 1, elPerPage = 50) => {
                          setActiveView("team")
                          setIsLoading(true);
                          getPromisedByLeader()
                        }}>
                        Ver Mi Equipo
                      </Button>
                      <Button
                        className='siignalBtn info'
                        onClick={() => {
                          setActiveView("general")
                          loadSavedPromises()
                        }}>Ver General</Button>
                    </ButtonGroup>
                  </GridLayoutItem>
                  <GridLayoutItem>
                      <DatePicker 
                        placeholder="Choose a date..."
                        format={"yyyy-MM-dd"}
                        value={value} 
                        onChange={handleChange}
                      />
                  </GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
              <GridLayoutItem>
                <div className='tipoGestionLegend'>
                  <ChipList
                    data={generatePageNumbers()}
                    chip={(props) => (
                      <Chip
                        {...props}
                        text={(props.dataItem.text).toString()}
                        themeColor={props.dataItem.themeColor}
                        onClick={props.dataItem.onClick}
                      />
                    )}
                  />
                </div>
              </GridLayoutItem>
            </GridLayout>
          </GridLayoutItem>
          <GridLayoutItem>
            <table className="renewalsAgentPanel-table">
              <thead>
                <tr>
                  <th className="renewalsAgentPanel-th"></th>
                  <th className="renewalsAgentPanel-th">Correo</th>
                  <th className="renewalsAgentPanel-th">NIT</th>
                  <th className="renewalsAgentPanel-th">Estado</th>
                  <th className="renewalsAgentPanel-th">Fecha Gestion</th>
                  <th className="renewalsAgentPanel-th">Fecha Compromiso</th>
                  <th className="renewalsAgentPanel-th">Fecha Promesa</th>
                  <th className="renewalsAgentPanel-th">Valor</th>
                  <th className="renewalsAgentPanel-th">Aplica NC</th>
                  <th className="renewalsAgentPanel-th">Id Nota Credito</th>
                  <th className="renewalsAgentPanel-th">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {savedPromises.map((item, index) => (
                  <>
                    <tr key={index} className={`renewalsAgentPanel-tr ${generateCustomClass(item)}`}>
                      <ColumnTipoDeGestion tipoGestion={item.dynamicInputsData.tablero.tipoGestion} />
                      <td className="renewalsAgentPanel-td">{item.dynamicInputsData.user}</td>
                      <td className="renewalsAgentPanel-td">{item.dynamicInputsData.NIT}</td>
                      <td className="renewalsAgentPanel-td">
                        {
                          editMode[index] ? <>
                            <select value={editingData[index].dynamicInputsData.tablero.estado} onChange={(event) => {
                              setEditingData((prevState) => ({
                                ...prevState,
                                [index]: {
                                  ...prevState[index],
                                  dynamicInputsData: {
                                    ...prevState[index].dynamicInputsData,
                                    tablero: {
                                      ...prevState[index].dynamicInputsData.tablero,
                                      estado: event.target.value
                                    }
                                  }
                                }
                              }));
                            }}>
                              <option value="Pendiente">Pendiente</option>
                              <option value="Pagado">Pagado</option>
                              <option value="Vencido">Vencido</option>
                            </select>
                          </> : item.dynamicInputsData.tablero.estado
                        }
                      </td>
                      <td className="renewalsAgentPanel-td">{item.dynamicInputsData.tablero.fechaGestion.split(" ")[0]}</td>
                      <td className="renewalsAgentPanel-td">{item.dynamicInputsData.tablero.fechaCompromiso}</td>
                      <td className="renewalsAgentPanel-td">{item.dynamicInputsData.tablero.fechaCompromisoExtendido}</td>
                      <ColumnValor item={item} index={index} editMode={editMode} editingData={editingData} setEditingData={setEditingData}/>
                      <td className="renewalsAgentPanel-td">
                        {item.dynamicInputsData.tablero.aplicaNC}
                      </td>
                      <ColumnNotaCredito item={item} index={index} editMode={editMode} editingData={editingData} setEditingData={setEditingData} />
                      <ColumnActions item={item} index={index} editMode={editMode} toggleEditMode={toggleEditMode} updatePromise={updatePromise} deletePromise={deletePromise} formatData={formatData} viewDetails={viewDetails} isAdmin={isAdmin} />
                    </tr>
                    {
                      showDetails[index] ? <>
                        <RowDetails item={item} customClass={generateCustomClass(item)} />
                      </> : null
                    }

                  </>
                ))}
              </tbody>
            </table>
          </GridLayoutItem>
        </>
      )}
    </GridLayout >
  );
};
export default AgentPanel;